import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { selectIsLoggedIn } from '@store/currentuser/currentuser.selectors';

@Injectable({ providedIn: 'root' })

/**
 * Restricts anonymous pages from being accessed by logged in users.
 */
export class AnonguardService implements CanActivate {
  constructor(private router: Router, private store: Store) {
  }

  canActivate(): Observable<boolean> {
    return this.store.pipe(
      select(selectIsLoggedIn),
      map(isLoggedIn => {
        if (isLoggedIn) {
          this.router.navigate(['browse']);
          return false;
        }
        return true;
      })
    );
  }
}
