import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';

import { ValueType, URLBasedContent } from '@models/types';
import { DeviceInfo, ConnectionStatus, DeviceScheduledEvents } from '@models/device.model';
import * as DeviceAPIActions from '@store/device/deviceapi.actions';
import * as UIActions from '@store/ui/ui.actions';
import { getCurrentDeviceId } from '@store/util';

@Injectable({
  providedIn: "root",
})
export class DevicesService {
  constructor(private backend: BackendService, private store: Store) {}

  get_my_devices() {
    return this.backend.get<DeviceInfo[]>("devices").pipe(
      tap((devices) => {
        this.store.dispatch(DeviceAPIActions.deviceListFetched({ devices }));
      })
    );
  }
  get_connection_status() {
    return this.backend.get<ConnectionStatus[]>("devices/connection_status");
  }

  get_info(device_id: string) {
    return this.backend
      .get<DeviceInfo>("devices", {
        id: device_id,
      })
      .pipe(
        tap((device) => {
          this.store.dispatch(
            UIActions.setOneLabelForIDEntry({
              id: device.device_id,
              label: device.device_name,
            })
          );
        })
      );
  }

  get_token(device_id: string) {
    return this.backend.get("devices/token", {
      id: device_id,
    });
  }

  deregister(device_id: string) {
    return this.backend.delete("devices", null, {
      id: device_id,
    });
  }
  set_current_device(device_id: string) {
    return this.backend.put("devices/current", {
      id: device_id,
    });
  }
  put_setting(device_id: string, setting: string, value: ValueType) {
    let changes = {};
    changes[setting] = value;
    return this.backend.put("devices", changes, {
      id: device_id,
    });
  }
  put_settings(device_id: string, changes) {
    return this.backend.put("devices", changes, {
      id: device_id,
    });
  }

  rename(device_id: string, new_name: string) {
    return this.put_setting(device_id, "device_name", new_name).pipe(
      tap((v) => {
        this.store.dispatch(
          DeviceAPIActions.deviceRenamed({ device_id, device_name: new_name })
        );
      })
    );
  }

  /// DEVICE Settings related API
  set_do_sleep(device_id, do_sleep) {
    let changes = {
      display_settings: {
        do_sleep: do_sleep,
      },
    };
    return this.put_settings(device_id, changes);
  }

  set_orientation(device_id, orientation) {
    let changes = {
      display_settings: {
        orientation: orientation,
      },
    };
    return this.put_settings(device_id, changes);
  }

  set_overlay_mode(device_id, overlay_mode) {
    let changes = {
      display_settings: {
        overlay_mode: overlay_mode,
      },
    };
    return this.put_settings(device_id, changes);
  }

  set_display_mode(device_id, display_mode) {
    let changes = {
      display_settings: {
        display_mode: display_mode,
      },
    };
    return this.put_settings(device_id, changes);
  }
  set_background_color(device_id: string, box_color: string) {
    let changes = {
      display_settings: {
        box_color: box_color,
      },
    };
    return this.put_settings(device_id, changes);
  }

  set_brightness(device_id, enable_manual_brightness, brightness) {
    let change = {
      enable_manual_brightness: enable_manual_brightness,
    };
    if (enable_manual_brightness === "on") {
      change["brightness"] = brightness;
    }
    let changes = {
      display_settings: change,
    };
    return this.put_settings(device_id, changes);
  }

  set_artsense(device_id, artsense) {
    let changes = {
      display_settings: {
        artsense: artsense,
      },
    };
    return this.put_settings(device_id, changes);
  }

  set_timezone(device_id, timezone) {
    let changes = {
      timezone: timezone,
    };
    return this.put_settings(device_id, changes);
  }

  set_playback_duration(device_id, playback_duration) {
    let changes = {
      playback_duration: playback_duration,
    };
    return this.put_settings(device_id, changes);
  }

  /// Events related API
  get_scheduled_events(device_id: string) {
    return this.backend
      .get<DeviceScheduledEvents>("devices/scheduled_events", {
        id: device_id,
      })
      .pipe(
        tap((device) => {
          this.store.dispatch(
            UIActions.setOneLabelForIDEntry({
              id: device.device_id,
              label: device.device_name,
            })
          );
        })
      );
  }
  delete_event(device_id: string, event_id: string) {
    return this.backend.delete("devices", {
      device: device_id,
      event: event_id,
    });
  }
  do_cleanup_events(device_id: string) {
    return this.backend.post("devices/scheduled_events/cleanup", null, {
      id: device_id,
    });
  }

  /*************************************************************************
   * PLAYBACK API
   *************************************************************************/
  preview_artwork(artwork_id: string, device_id?: string) {
    if (!device_id) {
      device_id = getCurrentDeviceId(this.store);
    }
    let body = {
      artwork: artwork_id,
      device: device_id,
      operation: "preview",
    };
    return this.backend.post("devices/queue_operations", body);
  }
  push_front_artwork(artwork_id: string, device_id?: string) {
    if (!device_id) {
      device_id = getCurrentDeviceId(this.store);
    }
    let body = {
      artwork: artwork_id,
      device: device_id,
      operation: "push_front",
    };
    return this.backend.post("devices/queue_operations", body);
  }
  push_back_artwork(artwork_id: string, device_id?: string) {
    if (!device_id) {
      device_id = getCurrentDeviceId(this.store);
    }
    let body = {
      artwork: artwork_id,
      device: device_id,
      operation: "push_back",
    };
    return this.backend.post("devices/queue_operations", body);
  }
  push_front_url(info: URLBasedContent, device_id?: string) {
    if (!device_id) {
      device_id = getCurrentDeviceId(this.store);
    }
    let body: any = {
      url: info.url,
      device: device_id,
      operation: "push_front",
    };
    if (info.title) {
      body.title = info.title;
    }
    if (info.details) {
      body.details = info.details;
    }
    if (info.thumbnail) {
      body.thumbnail = info.thumbnail;
    }
    return this.backend.post("devices/queue_operations", body);
  }
  push_front_playlist(playlist_id: string, device_id?: string) {
    if (!device_id) {
      device_id = getCurrentDeviceId(this.store);
    }
    let body = {
      playlist: playlist_id,
      device: device_id,
      operation: "push_front",
    };
    return this.backend.post("devices/queue_operations", body);
  }
  push_back_playlist(playlist_id: string, device_id?: string) {
    if (!device_id) {
      device_id = getCurrentDeviceId(this.store);
    }
    let body = {
      playlist: playlist_id,
      device: device_id,
      operation: "push_back",
    };
    return this.backend.post("devices/queue_operations", body);
  }
}
