import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBarHorizontalPosition } from '@angular/material/snack-bar';
import { createAction, props } from '@ngrx/store';


const msg_props = (message:string, action: string,
    duration:number=4000, hpos:MatSnackBarHorizontalPosition='left') => ({
    message,
    action,
    duration,
    hpos
});

export const TOAST_MESSAGE = createAction(
    '[Toaster] Message',
    msg_props
);

export const GOOGLE_OAUTH_START = createAction(
    '[OAuth] GOOGLE_OAUTH_START',
    props<{ method: string}>()
);

export const GOOGLE_OAUTH_END = createAction(
    '[OAuth] GOOGLE_OAUTH_END',
    props<{ query: any, state: any}>()
);


export const GOOGLE_PHOTOS_ITEMS_ACCESS_FAILURE = createAction(
    '[Google Photos API] GOOGLE_PHOTOS_ITEMS_ACCESS_FAILURE',
    props<{ error: HttpErrorResponse}>()
);

export const APP_INIT = createAction(
    '[App] APP_INIT'
);

export const LAYOUT_INIT = createAction(
    '[App] LAYOUT_INIT'
);


export const APPLE_OAUTH_START = createAction(
    '[OAuth] APPLE_OAUTH_START',
    props<{ method: string}>()
);

export const APPLE_OAUTH_END = createAction(
    '[OAuth] APPLE_OAUTH_END',
    props<{ query: any, state: any}>()
);

export const CANVIA_OAUTH_JWT_TOKEN_RECEIVED = createAction(
    '[OAuth] CANVIA_OAUTH_JWT_TOKEN_RECEIVED',
    props<{ flow: any, token: any, state: any}>()
);

