import { Injectable } from '@angular/core';
import { Actions, act, createEffect, ofType } from '@ngrx/effects';
import { tap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import * as qs from 'qs';
import { environment } from '@env/environment';

import * as AppActions from './app.actions';
import * as UserSelects from './currentuser/currentuser.selectors';
import * as UserActions from './currentuser/currentuser.actions';

import { LoginService } from '@services/login.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as Toaster from '@store/toaster';

@Injectable()
export class OAuthffects {

    /**
     * SUPPORT FOR GOOGLE OAUTH
     * This effect is triggered when the user clicks on the Google OAuth button.
     */
    handleGoogleOAuthStart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.GOOGLE_OAUTH_START),
            tap(action => {
                let method = action.method;
                /// Capture state to identify the source parameters of this OAuth request
                let state: any = {
                    method: method,
                    type: 'web',
                    app: 'my.canvia.art',
                    source: window.location.origin,
                    idp: 'google',
                    random: Math.random().toString(36).substring(2, 10)
                };
                // Convert the state to a BASE64 encoded string
                state = btoa(JSON.stringify(state));
                const GOOGLE_AUTHORIZATION_URL =
                    "https://accounts.google.com/o/oauth2/v2/auth";
                const GOOGLE_CLIENT_ID =
                    "135552502428-0nq5q9p0i0hfh7la9mths1vkadjaln5r.apps.googleusercontent.com";
                const GOOGLE_REDIRECT_URI = "https://prod.palacio.life/backend/api/v1";
                const urlParams = {
                    response_type: "code",
                    redirect_uri: window.location.origin,
                    client_id: GOOGLE_CLIENT_ID,
                    scope: "profile email https://www.googleapis.com/auth/photoslibrary.readonly",
                    access_type: "offline",
                    state: state,
                    prompt: undefined
                };
                if (method == 'connect' || method == 'signup') {
                    urlParams.prompt = 'consent';
                }
                let query = qs.stringify(
                    urlParams
                );
                const oauth_url = `${GOOGLE_AUTHORIZATION_URL}?${query}`;
                window.open(
                    oauth_url,
                    "_self"
                );
            })
        ), { dispatch: false });

    handleGoogleOAuthEnd$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.GOOGLE_OAUTH_END),
            withLatestFrom(this.store.select(UserSelects.currentUser)),
            tap(([action, user]) => {
                let query = action.query;
                let code = query['code'];
                let state = action.state;
                let method = state['method'];
                if (user.isLoggedIn) {
                    /// We have to connect the google account
                    this.spinner.show();
                    this.loginService.connectWithGoogle(code).subscribe(data => {
                        this.spinner.hide();
                        this.store.dispatch(UserActions.googleAccountLinked({ data }));
                        this.router.navigate(['account'])
                    }, error => {
                        this.spinner.hide();
                        Toaster.Error(this.store, error.message);
                    })
                }
                else {
                    /// We have to login with the google account
                    if (method === 'login') {
                        this.spinner.show()
                        this.loginService.LoginwithGoogle(code).subscribe(data => {
                            this.spinner.hide()
                            this.store.dispatch(UserActions.loginWithRawDataAction({ data }));
                            this.router.navigate(['browse']);
                        }, error => {
                            this.spinner.hide()
                            Toaster.Error(this.store, error.message);
                        });
                    } else {
                        // We have to sign up with the google account
                        this.spinner.show()
                        this.loginService.SignUpwithGoogle(code).subscribe(data => {
                            this.spinner.hide()
                            this.store.dispatch(UserActions.loginWithRawDataAction({ data }));
                            this.router.navigate(['register', 'preferences']);
                        }, error => {
                            this.spinner.hide()
                            Toaster.Error(this.store, error.message);
                        });
                    }
                }
            })
        ), { dispatch: false });


    /**
     * SUPPORT FOR APPLE OAUTH
     * This effect is triggered when the user clicks on the Apple OAuth button.
     */
    handleAppleOAuthStart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.APPLE_OAUTH_START),
            tap(action => {
                let method = action.method;
                /// Capture state to identify the source parameters of this OAuth request
                let state: any = {
                    method: method,
                    type: 'web',
                    app: 'my.canvia.art',
                    source: window.location.origin,
                    idp: 'apple',
                    random: Math.random().toString(36).substring(2, 10)
                };
                // Convert the state to a BASE64 encoded string
                state = btoa(JSON.stringify(state));
                // Setup the parameters for the Apple OAuth request
                const APPLE_AUTHORIZATION_URL =
                    "https://appleid.apple.com/auth/authorize";
                const APPLE_CLIENT_ID = "canvia";
                const urlParams = {
                    response_type: "code",
                    redirect_uri: environment.api_Url + 'auth/signin/apple',
                    client_id: APPLE_CLIENT_ID,
                    scope: "name email",
                    access_type: "offline",
                    response_mode: "form_post",
                    state: state,
                    prompt: undefined
                };
                if (method == 'connect' || method == 'signup') {
                    urlParams.prompt = 'consent';
                }
                let query = qs.stringify(
                    urlParams
                );
                const oauth_url = `${APPLE_AUTHORIZATION_URL}?${query}`;
                window.open(
                    oauth_url,
                    "_self"
                );
            })
        ), { dispatch: false });

    handleAppleOAuthEnd$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.APPLE_OAUTH_END),
            withLatestFrom(this.store.select(UserSelects.currentUser)),
            tap(([action, user]) => {
                let query = action.query;
                let code = query['code'];
                let state = action.state;
                let method = state['method'];
                if (user.isLoggedIn) {
                    /// We have to connect the apple account
                    this.spinner.show();
                    this.loginService.connectWithApple(code).subscribe(data => {
                        this.spinner.hide();
                        this.store.dispatch(UserActions.appleAccountLinked({ data }));
                        this.router.navigate(['account'])
                    }, error => {
                        this.spinner.hide();
                        Toaster.Error(this.store, error.message);
                    })
                }
                else {
                    /// We have to login with the apple account
                    if (method === 'login') {
                        this.spinner.show()
                        this.loginService.loginWithApple(code).subscribe(data => {
                            this.spinner.hide()
                            this.store.dispatch(UserActions.loginWithRawDataAction({ data }));
                            this.router.navigate(['browse']);
                        }, error => {
                            this.spinner.hide()
                            Toaster.Error(this.store, error.message);
                        });
                    } else {
                        // We have to sign up with the apple account
                        this.spinner.show()
                        this.loginService.signupWithApple(code).subscribe(data => {
                            this.spinner.hide()
                            this.store.dispatch(UserActions.loginWithRawDataAction({ data }));
                            this.router.navigate(['register', 'preferences']);
                        }, error => {
                            this.spinner.hide()
                            Toaster.Error(this.store, error.message);
                        });
                    }
                }
            })
        ), { dispatch: false });


    handleCanviaTokenReceived$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.CANVIA_OAUTH_JWT_TOKEN_RECEIVED),
            withLatestFrom(this.store.select(UserSelects.currentUser)),
            tap(([action, user]) => {
                let token = action.token;
                //let state = action.state;
                let flow = action.flow;
                /// We need to save the token to the store
                this.spinner.show()
                this.store.dispatch(UserActions.tokenOnlyLoginAction({ token }));
                this.loginService.getCurrentUserProfile().subscribe(data => {
                    this.spinner.hide()
                    /// We need to save the user profile information
                    this.store.dispatch(UserActions.loginWithRawDataAction({ data }));
                    if (flow === 'login') {
                        this.router.navigate(['browse']);
                    }
                    else if (flow === 'signup') {
                        this.router.navigate(['register', 'preferences']);
                    }
                    else if (flow == 'connect') {
                        this.store.dispatch(UserActions.appleAccountLinked({ data }));
                        this.router.navigate(['account']);
                    }
                }, error => {
                    this.spinner.hide();
                    Toaster.Error(this.store, error.message);
                });
            })
        ), { dispatch: false });


    constructor(private actions$: Actions,
        private router: Router,
        private store: Store,
        private loginService: LoginService,
        private spinner: NgxSpinnerService,
    ) { }
}
