import { createReducer, on } from '@ngrx/store';
import { QueueModel, flatten } from "@models/queue.model";

import * as Actions from './device.actions';

const initialState: QueueModel = {
    queue: [],
    is_playing: false,
    playback_duration: 300,
    shuffle: false,
    total: 0,
    now_playing_title: '',
    now_playing_id: ''
};

export const reducer = createReducer(
    initialState,
    on(Actions.queueContentsReceived, (state, { payload }) => {
        let model = flatten(payload);
        return model;
    }),
);
