import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { selectIsLoggedIn } from '@store/currentuser/currentuser.selectors';

@Injectable({ providedIn: 'root' })

/**
 * Restricts content to be acccessed by anonymous users. Only logged in users can access 
 * pages guarded by this guard.
 */
export class AuthGuardService implements CanActivate {

  constructor(private router: Router, private store: Store) {
  }

  canActivate(): Observable<boolean> {
    return this.store.pipe(
      select(selectIsLoggedIn),
      map(isLoggedIn => {
        if (isLoggedIn) {
          return true;
        }
        this.router.navigate(['/login']);
        return false;
      })
    );
  }
}
