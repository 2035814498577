import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgxSpinnerModule } from "ngx-spinner";

import { MaterialModule } from './modules/material.module';
import { DurationSecondsPipe } from './pipes/duration-seconds.pipe';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';

const modules = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    MaterialModule,
]

const pipes = [
    DurationSecondsPipe
];

const sharedComponents = [
    BreadcrumbComponent
];

@NgModule({
    imports: [
        RouterModule,
        ...modules
    ],
    declarations: [
        ...pipes,
        ...sharedComponents
    ],
    exports: [...modules,
    ...pipes,
    ...sharedComponents
    ]
})
export class SharedModule { }
