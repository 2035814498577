import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { tap, map, mergeMap, withLatestFrom, catchError } from 'rxjs/operators';
import { SocketService } from '@services/socket.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import * as DeviceActions from './device.actions';

import { Store } from '@ngrx/store';
import * as DeviceSelects from '@store/device/device.selectors';

@Injectable()
export class SocketIOEffects {

    handleAck$ = createEffect(() => this.actions$.pipe(
        ofType(DeviceActions.sioAckReceived),
        tap(action => {
            let ack = action.ack;
            console.log('Acknowledgement: ', ack);
            if (ack.message) {
                this.snackBar.open(ack.message, 'Success', {
                    duration: 3000,
                    horizontalPosition: 'right'
                });
            }
        })
    ), { dispatch: false });

    // if queue becomes visible, then fetch queue contents
    handleShowQueue$ = createEffect(() => this.actions$.pipe(
        ofType(DeviceActions.showQueue, DeviceActions.toggleQueue),
        withLatestFrom(this.store.select(DeviceSelects.selectIsQueueVisible)),
        tap(([action, queue_visible]) => {
            if (queue_visible) {
                this.socketService.getQueue();
            }
        })
    ), { dispatch: false });

    /// If queue is visible and now_playing message received, then update it
    handleNowPlaying$ = createEffect(() => this.actions$.pipe(
        ofType(DeviceActions.setNowPlaying),
        withLatestFrom(this.store.select(DeviceSelects.selectIsQueueVisible)),
        tap(([action, queue_visible]) => {
            if (queue_visible) {
                this.socketService.getQueue();
            }
        })
    ), { dispatch: false });


    // update queue on acknowledgement
    handleAckQueue$ = createEffect(() => this.actions$.pipe(
        ofType(DeviceActions.sioAckReceived),
        withLatestFrom(this.store.select(DeviceSelects.selectIsQueueVisible)),
        tap(([action, queue_visible]) => {
            let ack = action.ack;
            if (ack.command) {
                if (ack.command == 'play' || ack.command == 'pause') {
                    /// We need to get the latest status of is_playing flag
                    this.socketService.getQueue();
                    return;
                }
            }
            // for all other cases, we can ignore if queue is not visible
            if (queue_visible) {
                // Let's see if an operation has happened which led to queue update
                if (ack.operation) {
                    // if an artwork of playlist has been removed, we need to update queue contents
                    if (ack.operation === 'remove_artwork' || ack.operation === 'remove_playlist') {
                        this.socketService.getQueue();
                    }
                }
            }
        })
    ), { dispatch: false });


    handleSIOEvent$ = createEffect(() => this.actions$.pipe(
        ofType(DeviceActions.sioEventReceived),
        withLatestFrom(this.store.select(DeviceSelects.selectIsQueueVisible)),
        tap(([action, queue_visible]) => {
            let event = action.event;
            let module = action.module;
            let details = action.details;
            console.log(event, module, details);
            // for all other cases, we can ignore if queue is not visible
            if (queue_visible) {
                // Let's see if an operation has happened which led to queue update
                let update_queue = false;
                if (event == 'SETTING_UPDATED'){
                    if ('playback_shuffle' in details){
                        update_queue = true;
                    }
                }
                if (update_queue){
                    this.socketService.getQueue();
                }
            }
        })
    ), { dispatch: false });
    

    constructor(private actions$: Actions,
        private socketService: SocketService,
        private snackBar: MatSnackBar,
        private store: Store) {

    }
}
