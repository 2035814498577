import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectIsLoggedIn, selectHasDevices } from '@store/currentuser/currentuser.selectors';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  isLoggedIn$ = this.store.select(selectIsLoggedIn);
  hasDevices$ = this.store.select(selectHasDevices);
  currentYear = new Date().getFullYear();

  constructor(
    private store: Store
  ) {
  }

  ngOnInit() {
  }
}