import { NgModule } from '@angular/core';

// Angular Material 
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import {MatDividerModule} from '@angular/material/divider';

const materialModules = [
        /// Angular material modules
        MatChipsModule,
        MatIconModule,
        MatDatepickerModule,
        MatInputModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatRadioModule,
        MatSelectModule,
        MatCheckboxModule,
        MatSliderModule,
        MatSnackBarModule,
        MatTableModule,
        MatPaginatorModule,
        MatButtonModule,
        MatTooltipModule,
        MatCardModule,
        MatToolbarModule,
        MatDividerModule
]

@NgModule({
    declarations: [],
    imports: [
      ...materialModules
    ],
    exports: [
      ...materialModules
    ]
  })
  export class MaterialModule { }

