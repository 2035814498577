import { createReducer, on } from '@ngrx/store';
import { adapter, DeviceInfo, Devices, NowPlaying } from "@models/device.model";

import * as Actions from './device.actions';
import * as APIActions from './deviceapi.actions';

import { loginWithRawDataAction } from '@store/currentuser/currentuser.actions';

const initialState: Devices = {
    ...adapter.getInitialState(),
    current_id: null,
    current_name: null,
    online: false,
    now_playing: null,
    queue_visible: false,
}

export const reducer = createReducer(
    initialState,
    on(loginWithRawDataAction, (state, { data }) => {
        let devices: DeviceInfo[] = [];
        if (data.devices && data.devices.length > 0) {
            devices = data.devices.map(v => ({
                device_id: v._id,
                device_name: v.device_name
            }));
            state = adapter.setAll(devices, state);
            let current_id = data.current_device;
            let current_name = null;
            for (let d of data.devices) {
                if (d._id == current_id) {
                    current_name = d.device_name;
                }
            }
            // If current device is not specified in from the backend, we will pick the first device.
            // Also if a wrong current device is specified in the backend.
            if (!current_id || !current_name) {
                let d = data.devices[0];
                current_id = d._id;
                current_name = d.device_name;
            }
            state = {
                ...state,
                current_id,
                current_name
            };
        }
        return state;
    }),
    on(Actions.setCurrentDevice, (state, { id }) => {
        let current_id = null;
        let current_name = null;
        if (id in state.entities) {
            let device = state.entities[id];
            current_id = device.device_id;
            current_name = device.device_name;
        }
        return { ...state, current_id, current_name };
    }),
    on(Actions.sioAckReceived, (state, { ack }) => {
        return {
            ...state,
            online: true,
        };
    }),
    on(Actions.setOnlineStatus, (state, { online }) => {
        let now_playing = online ? state.now_playing : null;
        return {
            ...state,
            online,
            now_playing
        };
    }),
    on(Actions.setNowPlaying, (state, { payload }) => {
        if (!payload) {
            // Not sure how did it get called. We won't change the state.
            return state;
        }
        let online = true;
        let now_playing: NowPlaying = {
            source: payload.source,
            animation: payload.animation,
            index_1: payload.index_1,
            index_2: payload.index_2,
            playlist: payload.playlist,
            playlist_id: payload.playlist_id,
            artwork: payload.artwork,
            artwork_id: payload.artwork_id,
            start_time: new Date(payload.start_time),
            tn: payload.tn,
            url: payload.url
        }
        return {
            ...state,
            online,
            now_playing
        };
    }),
    on(Actions.setNowPlayingFromQueue, (state, { payload }) => {
        return {
            ...state,
            online: true,
            now_playing: payload
        };
    }),
    on(Actions.showQueue, (state) => {
        return {
            ...state,
            queue_visible: true
        };
    }),
    on(Actions.hideQueue, (state) => {
        return {
            ...state,
            queue_visible: false
        };
    }),
    on(Actions.toggleQueue, (state) => {
        return {
            ...state,
            queue_visible: !state.queue_visible
        };
    }),
    /// Handling of DEVICE API 
    on(APIActions.deviceListFetched, (state, { devices }) => {
        state = adapter.setAll(devices, state);
        let current_id = state.current_id;
        let ids = state.ids as string[];
        let index = ids.indexOf(current_id);
        if (index < 0 && state.ids.length > 0){
            // pick the first device
            let id = state.ids[0];
            let device  = state.entities[id];
            state = {
                ...state,
                current_id: device.device_id,
                current_name: device.device_name
            };
        }
        return state;
    }),
    /// Handle device renaming
    on(APIActions.deviceRenamed, (state, { device_id, device_name }) => {
        let update = {
            id: device_id,
            changes: {
                device_name
            }
        };
        state = adapter.updateOne(update, state);
        return state;
    })
);
