import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";


export type EventType = 'wakeup' | 'sleep' | 'playlist' | 'artwork' | 'inspire';

export type DayOfWeek = 'Sun' | 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat';

export type Month = 'Jan' | 'Feb' | 'Mar' | 'Apr' | 'May' | 'Jun' | 'Jul' | 'Aug' | 'Sep' | 'Oct' | 'Nov' | 'Dec';

import { Artwork } from "./artwork.model";
import { Playlist } from "./playlist.model";
import { PremiumSubscription } from "./subscriptions.model";

export interface DeviceEvent {
    _id: string;
    event_type: EventType;
    artwork?: Artwork;
    playlist?: Playlist;
    title?: string;
    hour: number;
    minute: number;
    flush: boolean;
    days_of_week?: DayOfWeek[];
    days_of_month?: number[];
    months?: Month[];
    years?: number[];
    start?: Date;
    end?: Date;
    createdAt: Date;
    updatedAt: Date;
}

export interface DeviceEventShortEntry {
    event_type: EventType;
    hour: number;
    minute: number;
    interval: number;
    id: string;
};

export type DayOfWeekWiseEvents = {
    [key: string] : DeviceEventShortEntry
};

export interface DeviceScheduledEvents {
    device_id: string;
    device_name: string;
    playback_duration: number;
    events: DeviceEvent[];
    day_of_week_wise: DayOfWeekWiseEvents;
};

export interface ArtSenseParams {
    component_scalar: number;
    attenuate_color_weight: number;
    component_curve_root: number;

};

export interface DisplaySettings {
    orientation?: string;
    enable_manual_brightness?: string;
    brightness?: number;
    overlay_mode?: string;
    display_mode?: string;
    filler_mode?: string;
    do_sleep?: boolean;
    box_color?: string;
    artsense?: ArtSenseParams;
};

export interface ConnectionStatus {
    _id: string;
    status: string;
};

export interface DeviceInfo {
    device_id: string;
    device_name: string;
    device_mac?: string;
    device_serial_id?: string;
    active_premium_subscription?: boolean;
    user_id?: string;
    user?: string;
    playback_duration?: number;
    playback_shuffle?: false;
    playback_mode?: string;
    timezone?: string;
    registration_time?: Date;
    device_wifi_name?: string;
    wifi_ip_address?: string;
    usage_last_sync_time?: Date;
    firmware_version?: string;
    qt_version?: string;
    online? : boolean;
    online_status?: string;
    display_settings?: DisplaySettings;
    subscription?: PremiumSubscription;
};


export const getMonthNumber = (month: Month) => {
    let monthMapping  = {
        'Jan': 1,
        'Feb': 2,
        'Mar': 3,
        'Apr': 4,
        'May': 5,
        'Jun': 6,
        'Jul': 7,
        'Aug': 8,
        'Sep': 9,
        'Oct': 10,
        'Nov': 11,
        'Dec': 12
      };
      return monthMapping[month];
};


export interface NowPlaying {
    source?: string;
    animation?: string;
    index_1?: number;
    index_2?: number;
    playlist?: string;
    playlist_id?: string;
    artwork?: string;
    artwork_id?: string;
    start_time?: Date;
    tn?: string;
    url?: string;
};

export interface Devices extends EntityState<DeviceInfo> {
    //! ID of the current device
    current_id?: string;
    //! Name of the current device
    current_name?: string;
    //! Offline online status of the current device
    online: boolean;
    //! information about the now playing artwork
    now_playing: NowPlaying;
    //! indicates if the queue is visible in the UI
    queue_visible: boolean;
}

function sortByName(e1: DeviceInfo, e2: DeviceInfo) {
    return e1.device_name.localeCompare(e2.device_name);
}

export const adapter: EntityAdapter<DeviceInfo> =
    createEntityAdapter<DeviceInfo>({
        selectId: (entity: DeviceInfo) => entity.device_id,
        sortComparer: sortByName
    });


export const now_playing_from_queue = (model) => {
    let i1 = model.index_1;
    let i2 = model.index_2;
    if (!model.queue){
        return null;
    }
    let q = model.queue;
    if (q.length == 0 || i1 >= q.length){
        return null;
    }
    let entry = model.queue[i1];
    let aw = entry;
    let is_playlist = 'artworks' in entry;
    if (is_playlist){
        aw = entry.artworks[i2];
    }
    let info : NowPlaying = {
        index_1 : i1,
        index_2 : i2,
        playlist: is_playlist ? entry.name : '',
        playlist_id: is_playlist ? entry.pl: '',
        artwork: aw.tt,
        artwork_id: aw.aw,
        tn: aw.tn,
        url: aw.url
    };
    return info;
}