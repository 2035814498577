<div class="container-fluid artwork-player">
    <div class="artwork-details " (click)="showQueue()" style="cursor: pointer;">
        <div class="playing-details fl" style="cursor: pointer">
            <div *ngIf="now_playing_tn$ | async as now_playing_tn">
                <img class="mr-3" [src]="now_playing_tn" style="width: 75px;
                height: 68px;
                object-fit: cover;
                position: fixed;
                bottom: 0;
                left: 0;" onError="this.style.display='none'" alt="">
            </div>
            <h3 class="artwork-name " [class.varLeft]="now_playing_tn$ | async"> {{now_playing_title$ | async
                |truncate:35}}
            </h3>
            <!--        <p class="">{{artwork['title']|truncate}}</p>-->
        </div>
        <div *ngIf="queue_is_not_empty$ | async"
            style="display: flex;justify-content: center;align-items: center;margin-right: 20px;">
            <a href="javascript:void(0)" *ngIf="!(queue_visible$ | async)" class="up_btn active" title="Show Queue"></a>
            <a href="javascript:void(0)" *ngIf="(queue_visible$ | async)" class="down_btn active"
                title="Hide Queue"></a>

        </div>
    </div>
    <div class="artwork-wrapper">
        <div class="artwork-controler bx-black" style="width:50%">
            <a href="javascript:void(0)" (click)="pre()" class="pre">
                <img src="./assets/newImgs/left.svg">
            </a>
            <a href="javascript:void(0)" *ngIf="!(is_playing$ | async)" (click)="play()" class="play_btn"
                title="Play"></a>
            <a href="javascript:void(0)" *ngIf="(is_playing$ | async)" (click)="pause()" class="play_btn active"
                title="Pause"></a>
            <a href="javascript:void(0)" (click)="next()" class="next" title="Next">
                <img src="./assets/newImgs/right.svg">
            </a>
        </div>

        <!-- <div class="share mr_15">
      <a href="javascript:void(0)" class="mr_15">
      <i class="material-icons">
      share
      </i>
      </a>

      <a href="javascript:void(0)" class="mr_15">
      <i class="material-icons">
      shuffle
      </i>
      </a>
      </div> -->

        <!-- TODO -->
        <!-- <div class="thumb-Holder">

      </div> -->
        <div class="fl d-flex justify-content-between align-item-center" style="width:50%">
            <div class="d-flex">
                <a href="javascript:void(0)" class="hideonmobile mr-2" style="align-items: center;display: flex;">
                    <img class="wifi-img" *ngIf="! (online$ | async)" src="./assets/img/no-wifi1.png" alt="">
                    <img class="wifi-img" *ngIf="online$ | async" src="./assets/newImgs/wifi.svg" alt="">
                </a>
                <div class="dropup device-select-box" style="margin-top: -8px">
                    <mat-form-field>
                        <mat-select [(ngModel)]="device_id" (selectionChange)="changeDevice()">
                            <mat-option *ngFor="let device of (devices$ | async )" style="text-transform: none;"
                                [value]="device.device_id">
                                {{device.device_name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <mat-menu #menu="matMenu">
                <a mat-menu-item [routerLink]="['devices', device_id, 'events']">
                    <!-- <img class="setting-img" src="./assets/newImgs/moon.svg"> -->
                    <span>Scheduled Events</span>
                </a>
                <a mat-menu-item [routerLink]="['devices', device_id, 'settings']">
                    <span>Settings</span>
                </a>
                <a mat-menu-item routerLink='/devices'>
                    <span>My Devices</span>
                </a>
            </mat-menu>
            <a href="javascript:void(0)" style="width:65px;display:flex"
                class="justify-content-center align-items-center" [matMenuTriggerFor]="menu"
                aria-label="Example icon-button with a menu">
                <img class="wifi-img2" src="./assets/img/menu.png" alt="">
                <!-- <i class="material-icons vert-size">
                more_vert
            </i> -->
            </a>
        </div>
    </div>
</div>
<!-- Small screen player -->
<!-- Mobile View more option -->
<div id="moreOptionModal" *ngIf="moreOptionModal">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header modal-header pt-0 py-3 px-0 mx-0" style="border:1px solid">
                <h4 class="modal-title" style="font-size: 24px;">More Option
                    <a href="javascript:void(0)" (click)="moreOptionModal= false" style="float:right">
                        <i class="material-icons">close</i>
                    </a>
                </h4>
            </div>
            <a href="javascript:void(0)" mat-menu-item [routerLink]="['devices', device_id, 'events']"
                (click)="moreOptionModal = false;">
                <span>Scheduled Events</span>
            </a>
            <a href="javascript:void(0)" mat-menu-item [routerLink]="['devices', device_id, 'settings']"
                (click)="moreOptionModal = false;">
                <span>Settings</span>
            </a>
        </div>
    </div>
</div>
<div class="container-fluid bottom-fixed">
    <div class="row py-2 px-2 mobile-player-1 mobile-player-3" [class.mobile-player-4]="no_devices_flag$ | async">
        <div class="col-2 d-flex flex-column justify-content-center mx-0 px-0 align-content-center text-center">
            <ng-container *ngIf="! (online$ | async)">
                <img class="wifi-img" src="./assets/img/no-wifi1.png" alt="">
            </ng-container>
            <ng-container *ngIf="online$ | async">
                <ng-container *ngIf="now_playing_tn$ | async as now_playing_tn">
                    <img style="height:100%;max-height:49.5px" [src]="now_playing_tn" alt="" srcset=""
                        (click)="showQueue()">
                </ng-container>
                <ng-container *ngIf="!(now_playing_tn$ | async)">
                    <div class="stage" (click)="showQueue()">
                        <div class="dot-falling"></div>
                    </div>
                </ng-container>
                <div style="position: absolute;left: 75px;">
                    <a href="javascript:void(0)" *ngIf="!(queue_visible$ | async)" (click)="showQueue()"
                        class="up_btn active" title="Show Queue"></a>
                    <a href="javascript:void(0)" *ngIf="(queue_visible$ | async)" (click)="showQueue()"
                        class="down_btn active" title="Hide Queue"></a>
                </div>
            </ng-container>
        </div>
        <div class="col-8 mx-0 px-0">
            <div class="text-center">
                <div class="d-flex w-100 justify-content-center align-items-center"
                    [class.nodevice]="no_devices_flag$ | async">
                    <a href="javascript:void(0)" (click)="pre()" class="pre mx-2">
                        <img src="./assets/newImgs/left.svg">
                    </a>
                    <a href="javascript:void(0)" *ngIf="!(is_playing$ | async)" (click)="play()" class="play_btn"
                        title="Play"></a>
                    <a href="javascript:void(0)" *ngIf="(is_playing$ | async)" (click)="pause()" class="play_btn active"
                        title="Pause"></a>
                    <a href="javascript:void(0)" (click)="next()" class="next mx-2" title="Next">
                        <img src="./assets/newImgs/right.svg">
                    </a>
                </div>
                <div class="mobile-device-dropdown" *ngIf="(devices$ | async) as devices">
                    <ng-container *ngIf="devices.length != 0">
                        <mat-form-field>
                            <mat-select [(ngModel)]="device_id" (selectionChange)="changeDevice()">
                                <mat-option *ngFor="let device of devices" [value]="device.device_id">
                                    {{device.device_name|truncate:15}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="col-2 d-flex flex-column justify-content-center align-items-end mx-0 px-1">
            <a (click)="openmoreOptionModal()">
                <img class="wifi-img2" src="./assets/img/menu.png" alt="">
            </a>
        </div>
    </div>
</div>
<!-- End small screen player  -->
<!--"[{"playlist_id":"5c9cedd0c1a8582803efa89e","artworks":[{"id":"5bd5b2bc3e561f0b705d9ab5","tt":"The Rocky Mountains, Lander's Peak"},{"id":"5bd5b2bc3e561f0b705d99f9","tt":"Merced River, Yosemite Valley"},{"id":"5bd5b2bc3e561f0b705d99c8","tt":"Fur Traders Descending the Missouri"},-->
<div class="all-queue" style="display: none;">
    <div class="queue-header">
        <div class="d-flex align-items-center">
            <h3 class="queue_heading"> Queue ({{queue_total$ | async}} Artworks)</h3>
        </div>
        <div>
            <a href="javascript:void(0)" [matMenuTriggerFor]="menu1" aria-label="Example icon-button with a menu">
                <i class="material-icons">
                    more_vert
                </i>
            </a>
            <mat-menu #menu1="matMenu">
                <a href="javascript:void(0)" mat-menu-item (click)="flushQueue()">
                    <!-- <i class="material-icons">
                    calendar_today
                  </i> -->
                    <span>Clear Queue</span>
                </a>
                <a href="javascript:void(0)" *ngIf="!(is_shuffle$ | async)" mat-menu-item (click)="switchShuffle()">
                    <!-- <i class="material-icons">
                    calendar_today
                  </i> -->
                    <span>Shuffle Queue</span>
                </a>
                <a href="javascript:void(0)" *ngIf="(is_shuffle$ | async)" mat-menu-item (click)="switchShuffle()">
                    <!-- <i class="material-icons">
                    calendar_today
                  </i> -->
                    <span>Disable Shuffle</span>
                </a>
            </mat-menu>
        </div>
    </div>
    <div class="queue-list-container" id="queue-log">
        <span class="queue-list" *ngFor="let entry of (queue$ | async)">

            <span>
                <!-- the thumbnail -->
                <div [class.now-playing]="entry['now_playing']" class="queue-list-inner" style="cursor: pointer;"
                    (click)="playArtworkFromQueue(entry['count'],entry['id'],entry['url'])">
                    <div class="list-img fl" *ngIf="entry['is_playlist']===false">
                        <img style="cursor: pointer;" (click)="showArtworks(entry.id,$event)" *ngIf="entry['url']"
                            src="{{entry['url']}}" loading="lazy" onError="this.src='./assets/img/image_holder.png';">
                        <img style="cursor: pointer;" (click)="showArtworks(entry.id,$event)" *ngIf="!entry['url']"
                            src="{{entry['tn']}}" loading="lazy" onError="this.src='./assets/img/image_holder.png';">
                    </div>
                    <!-- the playlist information -->
                    <div class="list-info fl" *ngIf="entry['is_playlist']===false" style="width: 80%;">
                        <h4 class="artwork-queue-name" *ngIf="!entry['url']">
                            {{entry['title']|truncate:28}} </h4>

                        <h4 class="artwork-queue-name" *ngIf="entry['url']">
                            {{entry['title']|truncate:28}} </h4>

                        <span tooltip="Remove" class="pull-right show-dlt-btn"
                            *ngIf="!entry['url']&&entry['in_playlist']===false"
                            style="cursor: pointer;padding-right:15px"
                            (click)="deleteArtworkFromQueue(entry['count'],entry['id'],$event)">
                            <i class="fa fa-minus" aria-hidden="true"></i>
                        </span>

                        <span tooltip="Remove" class="pull-right show-dlt-btn" *ngIf="entry['url']"
                            style="cursor: pointer;padding-right:15px"
                            (click)="deleteArtworkFromURLQueue(entry['count'],entry['url'],$event)">
                            <i class="fa fa-minus" aria-hidden="true"></i>
                        </span>
                    </div>
                    <!-- the artwork/URL information -->
                    <div class="list-info fl" *ngIf="entry['is_playlist']===true"
                        style="width: 100%;padding-top:10px;padding-bottom:10px;height: 65px">
                        <h4 class="artwork-queue-name" style="color: #000000;font-size:15px">
                            Playlist: {{entry['title']|truncate:28}} </h4>

                        <span tooltip="Remove" class="pull-right show-dlt-btn" style="cursor: pointer;    padding-right: 15px;
        padding-top: 10px;" (click)="deletePlaylistFromQueue(entry['index_1'],entry['id'],$event)">
                            <i class="fa fa-minus" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>
            </span>

        </span>
    </div>
</div>
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>