import { createSelector, createFeatureSelector } from "@ngrx/store";

import { DeviceInfo, Devices, adapter } from "@models/device.model";

import { AppState } from "../app.state";

export const selectDevices = createFeatureSelector<
    AppState,
    Devices
>("devices");


export const selectCurrentDeviceId = createSelector(
    selectDevices,
    (devices: Devices) => {
        return devices.current_id
    }
);

export const selectCurrentDeviceName = createSelector(
    selectDevices,
    (devices: Devices) => {
        return devices.current_name
    }
);

export const selectCurrentDevice = createSelector(
    selectDevices,
    (devices: Devices) => {
        let current_id = devices.current_id;
        let device: DeviceInfo = devices.entities[current_id];
        if (!device) {
            device = {
                device_id: devices.current_id,
                device_name: devices.current_name
            };
        }
        return device;
    }
);

export const selectNowPlaying = createSelector(
    selectDevices,
    (devices: Devices) => {
        return devices.now_playing
    }
);

export const selectNowPlayingTitle = createSelector(
    selectDevices,
    (devices: Devices) => {
        if (!devices.online) {
            return 'Device Offline';
        }
        // TODO handle the case when queue is empty
        if (!devices.now_playing) {
            return 'Device Online';
        }
        let title = devices.now_playing.artwork;
        if (!title || title.length == 0) {
            return 'Device Online';
        }
        return title;
    }
);

export const selectNowPlayingThumbnail = createSelector(
    selectDevices,
    (devices: Devices) => {
        if (!devices.now_playing) {
            return '';
        }
        let np = devices.now_playing;
        if (np.tn) return np.tn;
        if (np.url) return np.url;
        return '';
    }
);


export const selectIsDeviceOnline = createSelector(
    selectDevices,
    (devices: Devices) => {
        return devices.online
    }
);

export const selectIsQueueVisible = createSelector(
    selectDevices,
    (devices: Devices) => {
        return devices.queue_visible
    }
);

const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal

} = adapter.getSelectors();

// select the array of device ids
export const selectDeviceIds = createSelector(
    selectDevices,
    selectIds
);

// select the dictionary of device entities
export const selectDeviceEntities = createSelector(
    selectDevices,
    selectEntities
);

// select the array of devices
export const selectAllDevices = createSelector(
    selectDevices,
    selectAll
);

// select the total device count
export const selectDeviceTotal = createSelector(
    selectDevices,
    selectTotal
);

// indicate if there are no devices
export const selectNoDevices = createSelector(
    selectDeviceTotal,
    (count: number) => count == 0
);

// indicate if there are devices devices
export const selectHasDevices = createSelector(
    selectDeviceTotal,
    (count: number) => count > 0
);