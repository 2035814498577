import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { MatMenuModule} from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { PlaylistBarComponent } from './playlist-bar/playlist-bar.component';
import { TruncateModule } from 'ng2-truncate';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxSpinnerModule } from "ngx-spinner";
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatSelectModule,
    FormsModule,
    TruncateModule,
    TooltipModule.forRoot(),
    MatSlideToggleModule,
    MatMenuModule,
    NgxSpinnerModule
  ],
  declarations: [PlaylistBarComponent],
  exports: [PlaylistBarComponent]
})
export class PlaylistBarModule {
}
