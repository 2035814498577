import { createAction, props } from '@ngrx/store';
import { IDLabel } from '@models/ui.model';


/// This action can be generated by any page which wants to specify
// some label for some entity id
export const setOneLabelForIDEntry = createAction(
    '[Page] setOneLabelForIDEntry',
    props<{ id: string, label: string}>()
);

export const setManyLabelForIDEntry = createAction(
    '[Page] setManyLabelForIDEntry',
    props<{ entries: IDLabel[]}>()
);
