import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { environment } from '@env/environment';
import { getHttpOptions } from '@store/util';

@Injectable({ providedIn: 'root' })

export class LoginService implements OnInit {


  constructor(
    private http: HttpClient,
    private store: Store) {
  }

  ngOnInit() {
  }

  login(request) {
    return this.http.post<any>(environment.api_Url + 'authenticate', request)
      .pipe(
        map(value => {
          return value;
        })
      );
  }

  login2(email, pass) {
    return this.http.post<any>(environment.api_Url + 'authenticate', {
      'email': email,
      'password': pass
    }).pipe(
      map(value => {
        return value;
      })
    );
  }

  emailVerification(email) {
    return this.http.post<any>(environment.api_Url + 'reset_password', { user_id: email })
      .pipe(
        map(value => {
          return value;
        })
      );
  }

  resetPassword(email, request, token) {
    return this.http.post<any>(environment.api_Url + 'reset_password', {
      'user_id': email,
      'token': token,
      'password': request.password
    }).pipe(
      map(value => {
        return value;
      })
    );
  }

  register(request) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>(environment.api_Url + 'users', {
      'first_name': request.firstname,
      'last_name': request.lastname,
      'email': request.email,
      'password': request.password
    }, httpOptions)
      .pipe(
        map(data => data)
      );
  }
  LoginwithGoogle(code) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>(environment.api_Url + 'auth/login/google?p=devices&p=preferences&code=' + code + '&redirect_uri=' + window.location.origin, httpOptions)
      .pipe(
        map(data => data)
      );
  }
  SignUpwithGoogle(code) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>(environment.api_Url + 'auth/signup/google?code=' + code + '&redirect_uri=' + window.location.origin, httpOptions)
      .pipe(
        map(data => data)
      );
  }
  connectWithGoogle(code) {
    let options = getHttpOptions(this.store);
    let qs = `code=${code}&redirect_uri=${window.location.origin}`;
    let url = `${environment.api_Url}auth/connect/google?${qs}`;
    return this.http.post<any>(url, {

    }, options);
  }

  /******************************************************************************
   * APPLE Oauth backend support APIs
   ******************************************************************************/

  loginWithApple(code) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>(environment.api_Url + 'auth/login/apple?p=devices&p=preferences&code=' + code + '&redirect_uri=' + window.location.origin, httpOptions)
      .pipe(
        map(data => data)
      );
  }
  signupWithApple(code) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>(environment.api_Url + 'auth/signup/apple?code=' + code + '&redirect_uri=' + window.location.origin, httpOptions)
      .pipe(
        map(data => data)
      );
  }
  connectWithApple(code) {
    let options = getHttpOptions(this.store);
    let qs = `code=${code}&redirect_uri=${window.location.origin}`;
    let url = `${environment.api_Url}auth/connect/apple?${qs}`;
    return this.http.post<any>(url, {

    }, options);
  }

  /******************************************************************************
   * Current user profile information
   ******************************************************************************/

  getCurrentUserProfile() {
    let options = getHttpOptions(this.store);
    let url = `${environment.api_Url}users/current_user_profile?devices=1`;
    return this.http.get<any>(url, options);
  }
}
