import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { map, mergeMap, exhaustMap, catchError } from 'rxjs/operators';
import { SocketService } from '@services/socket.service';

import * as DeviceActions from './device.actions';
import { DevicesService } from '@services/devices.service';

@Injectable()
export class DeviceEffects {
    changeDevice$ = createEffect(() => this.actions$.pipe(
        ofType(DeviceActions.changeCurrentDevice),
        exhaustMap(action => {
            /**
             * Following things need to be done
             * - save the new id to backend
             * - switch socket io to the new device id
             * - save the new id to store
             */
            let id = action.id;
            this.socketService.activateSocket(id);
            console.log(`Saving device id: ${id} to backend.`);
            this.devices.set_current_device(id).subscribe({complete: console.info});
            return of(action.id).pipe(
                map(id => DeviceActions.setCurrentDevice({ id })),
                catchError(error => of(DeviceActions.changeCurrentDeviceError({ error })))
            );
        }
        )
    )
    );
    constructor(private actions$: Actions,
        private socketService: SocketService,
        private devices: DevicesService) {
            

    }
}
