import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { Store } from '@ngrx/store';
import { logoutAction } from '@store/currentuser/currentuser.actions';
import * as UserSelects from '@store/currentuser/currentuser.selectors';

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
}

// Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: '/browse',
    title: 'Browse',
    type: 'link',
  },
  {
    path: '/mycollection',
    title: 'My Collection',
    type: 'link',
  },
  {
    path: '/mycrypto',
    title: 'NFT',
    type: 'link',
  },
];

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit {

  // Current user information
  userName$ = this.store.select(UserSelects.selectName);

  public menuItems: any[];
  searchKey = '';

  constructor(
    public userService: UserService,
    public router: Router,
    private store: Store) {
  }


  ngOnInit() {

    this.menuItems = ROUTES.filter(item => item);
  }

  search() {
    if (this.searchKey.length > 2) { this.router.navigate(['browse', 'search'], { queryParams: { searchKey: this.searchKey } }); }
  }

  gotoHome() {
    this.router.navigate(['browse']);
  }

  logout() {
    this.store.dispatch(logoutAction());
  }

  openSearch() {
    document.getElementById('myOverlay').style.display = 'block';
  }

  closeSearch() {
    document.getElementById('myOverlay').style.display = 'none';
  }
}
