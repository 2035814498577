<nav class="navbar navbar-expand-lg fixed-top">
    <div class="container">
        <div class="navbar-wrapper">
            <span class="navbar-brand" style="margin:0px" (click)="gotoHome()" href="javascript:void(0)">
                <img src="../../../assets/img/logo.png" alt="Logo">
            </span>
        </div>
        <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index"
            aria-expanded="false" aria-label="Toggle navigation" data-target="#custom-menu">
            <span class="sr-only">Toggle navigation</span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="custom-menu">
            <div class="col-md-9">
                <ul class="navbar-nav">
                    <li routerLinkActive="active" data-toggle="collapse" data-target=".navbar-collapse"
                        [routerLinkActiveOptions]="{ exact: true }" *ngFor="let menuitem of menuItems" class="nav-item">
                        <a [routerLink]="[menuitem.path]" class="nav-link"> {{menuitem.title}} </a>
                    </li>
                    <li class="nav-item">
                        <a href="https://www.canvia.art/setup" target="_blank" class="nav-link"> Support </a>
                    </li>
                    <li>
                        <form>
                            <button style="padding-right: 6px;padding-bottom:0px" class="btn btn-link"
                                (click)="search()"><i style="font-size: 1.2em!important;"
                                    class="fa fa-search"></i></button>
                            <input style="position: relative;top: 5px;left: 1px;" type="text" [(ngModel)]="searchKey"
                                (keyup)="search()" class="form-control" placeholder="" name="search">
                        </form>
                    </li>
                </ul>
            </div>
            <hr>
            <div class="col-md-3 colmd4">
                <ul class="navbar-nav pull-right">
                    <li class="nav-item dropdown" routerLinkActive="active" data-toggle="collapse"
                        data-target=".navbar-collapse" [routerLinkActiveOptions]="{ exact: true }">
                        <a class="nav-link usrname" href="" id="navbarDropdownMenuLink" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false" style="margin-left: 15px;">
                            <i class="material-icons">person</i> Hi {{userName$ | async}} <i
                                class="material-icons">arrow_drop_down</i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                            <a [routerLink]="'/account'" routerLinkActive="mySelected" class="dropdown-item usrname"
                                href="javascript:void(0)">
                                Account settings
                            </a>
                            <a [routerLink]="'/preferences'" routerLinkActive="mySelected" class="dropdown-item usrname"
                                href="javascript:void(0)">
                                Art Preferences
                            </a>
                            <a [routerLink]="'/account/subscriptions'" routerLinkActive="mySelected"
                                class="dropdown-item usrname" href="javascript:void(0)">
                                Subscriptions
                            </a>
                            <hr>
                            <a [routerLink]="'/privacy'" routerLinkActive="mySelected" class="dropdown-item usrname"
                                href="javascript:void(0)">
                                Privacy Policy & Terms of Use
                            </a>
                            <hr>
                            <a class="dropdown-item usrname" (click)="logout()" href="javascript:void(0)">
                                Logout
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>