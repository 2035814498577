import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { GOOGLE_OAUTH_END, APPLE_OAUTH_END, 
CANVIA_OAUTH_JWT_TOKEN_RECEIVED } from '@store/app.actions';
import { selectIsLoggedIn } from '@store/currentuser/currentuser.selectors';
@Component({
    selector: 'index-page',
    template: '<div></div>',
})
export class IndexComponent implements OnInit {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private store: Store

    ) {
        console.log(this.router.url);
    }

    ngOnInit(
    ) {
        let search = window.location.search;
        console.log(window.location);
        if (search.length > 0) {
            // There is a query string before #. 
            // E.g. a redirect from Google
            /// Let's move the query string after the #
            let new_url = window.location.href.replace('?', '#/?');
            console.log(new_url);
            window.location.replace(new_url);
            return;
        }
        this.route.queryParams.subscribe(v => {
            /// Check if this is a google authentication call back
            if ('state' in v) {
                let state: any = v['state'];
                /// Decode the state from a BASE64 encoded JSON object
                state = JSON.parse(atob(state));
                if ('code' in v) {
                    /// Check the IDP used for the authentication
                    if (state.idp == 'google') {
                        this.store.dispatch(GOOGLE_OAUTH_END({ query: v, state: state }));
                    }
                    else if (state.idp == 'apple') {
                        this.store.dispatch(APPLE_OAUTH_END({ query: v, state: state }));
                    }
                    return;
                }
                if ('token' in v){
                    /// We have received a token from the backend. Use it to login
                    let token = v['token'];
                    let flow = v['flow'] || 'login'
                    this.store.dispatch(CANVIA_OAUTH_JWT_TOKEN_RECEIVED({ flow, token, state}));
                    return;
                }
            }
            // Check if we are logged in
            this.store.select(selectIsLoggedIn).subscribe(isLoggedIn => {
                if (isLoggedIn) {
                    // We are logged in. Go to home page.
                    this.router.navigate(['/browse']);
                    return;
                }
                // We should redirect to the login page.
                this.router.navigate(['/login']);
                return;
            });
        });
    }

}