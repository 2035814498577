import { DeviceInfo } from '@models/device.model';
import { createAction, props } from '@ngrx/store';


export const deviceListFetched = createAction(
    '[Device API] deviceListFetched',
    props<{ devices: DeviceInfo[]}>()
);

export const deviceRenamed = createAction(
    '[Device API] deviceRenamed',
    props<{ device_id: string, device_name: string}>()
);
