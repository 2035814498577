import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getAccessToken, getHttpOptions } from '@store/util';
import * as DeviceActions from '@store/device/device.actions';
import { now_playing_from_queue } from '@models/device.model';
import * as Sentry from "@sentry/angular-ivy";

@Injectable({ providedIn: 'root' })

export class SocketService {

  socket: any;
  id = 1;
  queue = new Subject<any>();
  deviceOffline = new Subject<boolean>();
  artists = [];
  playlists = [];
  artistUpdated = new Subject<boolean>();

  constructor(private http: HttpClient, private store: Store) {
    this.artistUpdated.next(true);
  }

  checkForMatch(array, valueToMatch) {
    for (let i = 0; i < array.length; i++) {
      if (array[i]['_id'] === valueToMatch) {
        return true;
      }
    }
    return false;
  }

  activateSocket(device_id) {
    if (this.socket){
      // If there is an existing connection, close it
      this.socket.close();
    }
    /// Make sure that current device is offline
    this.store.dispatch(DeviceActions.setOnlineStatus({online: false}));
    // Get the access token
    let token = getAccessToken(this.store);
    // Setup a new socket-io connection
    this.socket = io(environment.device_url, {
      query: {
        user_token: token,
        device_id: device_id
      },
      transports: ['websocket'],
      requestTimeout: 120 * 1000,
      timeout: 120 * 1000,
      reconnectionDelay: 300 * 1000,
      rejectUnauthorized: false,
    });
    this.socketOn();
  }

  socketCommands(command) {
    this.id++;
    if (!this.socket){
      console.log(`No socket-io connection.`);
      return;
    }
    this.socket.emit('playback', {
      id: this.id,
      command: command
    });
  }

  getQueue() {
    this.socket.emit('current_queue', [{
      operation: 'get_contents',
      time: (new Date()).getTime()
    }]);
  }

  deleteArtworkFromQueue(count, art_id) {
    this.socket.emit('current_queue', [{
      operation: 'remove_artwork',
      time: (new Date()).getTime(),
      count: count,
      artwork: art_id
    }]);
    console.log('delete hit');
  }
  deletePlaylistFromQueue(index_1, id) {
    this.socket.emit('current_queue', {
      operation: 'remove_playlist',
      time: (new Date()).getTime(),
      playlist: id,
      index_1: index_1
    });
    console.log('delete playlist hit');
  }
  deleteArtworkFromURLQueue(count, url) {
    this.socket.emit('current_queue', [{
      operation: 'remove_artwork',
      time: (new Date()).getTime(),
      count: count,
      url: url
    }]);
  }

  playArtworkFromQueue(count, art_id) {
    this.socket.emit('current_queue', [{
      operation: 'play_artwork',
      time: (new Date()).getTime(),
      count: count,
      artwork: art_id
    }]);
    console.log('play hit');
  }
  playArtworkFromURLQueue(count, url) {
    console.log(url)
    this.socket.emit('current_queue', [{
      operation: 'play_artwork',
      time: (new Date()).getTime(),
      count: count,
      url: url
    }]);
  }

  socketOn() {
    let skt = this.socket;
    this.socket.on('ack', (response) => {
      this.store.dispatch(DeviceActions.sioAckReceived({ ack: response }));
    });
    this.socket.on('connect', function () {
      console.log('Connected to the server.');
      // Try to get the queue contents from device
      skt.emit('current_queue', [{
        operation: 'get_contents',
        time: (new Date()).getTime()
      }]);
    });
    this.socket.on('disconnect', function () {
      console.log('Disconnected from the server.');
    });
    this.socket.on('message', function (message) {
      console.log(message);
    });

    this.socket.on('current_queue_contents', (response) => {
      console.log('current_queue: ', response);
      this.queue.next(response);
      let payload = now_playing_from_queue(response);
      this.store.dispatch(DeviceActions.queueContentsReceived({payload: response}));
      this.store.dispatch(DeviceActions.setNowPlayingFromQueue({payload}));
    });

    this.socket.on('device_disconnection', (response) => {
      console.log('Device Disconnected ', response);
      this.store.dispatch(DeviceActions.setOnlineStatus({online: false}));
    });

    this.socket.on('device_connection', (response) => {
      console.log('Device Connected ', response);
      this.store.dispatch(DeviceActions.setOnlineStatus({online: true}));
    });

    this.socket.on('now_playing', (response) => {
      console.log('Now playing: ', response);
      if (response){
        this.store.dispatch(DeviceActions.setNowPlaying({payload: response}));
      }
    });
    this.socket.on('event', (payload) => {
      this.store.dispatch(DeviceActions.sioEventReceived({
        event : payload.event,
        module: payload.module,
        details: payload.details}));
    });

    /// Handling of socket-io connection related events
    this.socket.on('connect_error', (err) => {
      const errorMsg = this.onConnectError("connect_error", err);
      Sentry.captureMessage(errorMsg);
    });
    this.socket.on('connect_timeout', (err) => {
      this.onConnectError('connect_timeout', err);
    });
    this.socket.on('error', (err) => {
      const errorMsg = this.onConnectError("error", err);
      Sentry.captureMessage(errorMsg);
    });
    this.socket.on('reconnect_attempt', (err) => {
      this.onConnectError('reconnect_attempt', err);
    });
    this.socket.on('reconnect_error', (err) => {
      const errorMsg = this.onConnectError("reconnect_error", err);
      Sentry.captureMessage(errorMsg);
    });
    this.socket.on('reconnect_failed', (err) => {
      const errorMsg = this.onConnectError("reconnect_failed", err);
      Sentry.captureMessage(errorMsg);
    });
  }
  
  onConnectError(event, err) : string {
    const errorMsg = `socket-io: ${event}, ${err}`;
    console.log(errorMsg);
    return errorMsg;

  }

  getFavouriteArtists() {
    const httpOptions = getHttpOptions(this.store);
    return this.http.get<any>(environment.api_Url + 'preferences/artists?page=' + 1 + '&limit=50&p=cover_thumbnail_url', httpOptions)
      .pipe(
        map(data => {
          this.artists = data || [];
          return data;
        })
      );
  }

  getfavouritePlaylists() {
    const httpOptions = getHttpOptions(this.store);
    return this.http.get<any>(environment.api_Url + 'preferences/playlists?page=' + 1 + '&limit=50&p=paid&p=details', httpOptions)
      .pipe(
        map(data => {
          this.playlists = data || [];
          return data;
        })
      );
  }
}
