import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import { Store } from '@ngrx/store';
import { getCurrentDeviceId, getHttpOptions } from '@store/util';

// @ts-ignore
import { v4 as uuidv4 } from 'uuid';


@Injectable({providedIn: 'root'})

export class BrowseService {

  constructor(
    private http: HttpClient, 
    private store: Store) {
  }
  getHeaders() {
    return getHttpOptions(this.store);
  }

  CreateArtwork(artwork) {
    return this.http.post<any>(environment.api_Url + 'artworks', {
      'imageid': uuidv4(),
      'title': artwork.title,
      'details': artwork.details,
      'mediums': artwork.mediums,
      'subjects': artwork.subjects
    }, this.getHeaders()).pipe(
      map(data => data)
    );
  }

  getPreferences() {
    return this.http.get<any>(environment.api_Url + 'preferences/categories', this.getHeaders()).pipe(
      map(data2 => data2)
    );
  }
  checkGETAPI(url) {
    return this.http.get<any>(url)
  }

  getNFTS(signature,address) {
    return this.http.get<any>(`https://tokencast.net/Account/Tokens?whitelabeler=canvia&address=${address}&signature=${signature}`);
  }
  playNFTS(signature,address,formData) {
    return this.http.post<any>(`https://tokencast.net/Account/SetDeviceContent?address=${address}&signature=${signature}`,formData);
  }
  uploadPlaylistImage(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'multipart/form-data',
        'x-access-token': localStorage.getItem('token'),
        'x-user-id': localStorage.getItem('username')
      })
    };
    return this.http.post<any>(environment.api_Url + 'playlists/add_artwork', data, httpOptions).pipe(
      map(value => value)
    );
  }

  updateArtworkDetails(id: string, payload: { title?: string, details?: string}){
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'multipart/form-data',
        'x-access-token': localStorage.getItem('token'),
        'x-user-id': localStorage.getItem('username')
      })
    };
    return this.http.put<any>(`${environment.api_Url}artworks/${id}`, payload, httpOptions).pipe(
      map(updateArtwork => updateArtwork)
    )
  }
  uploadArtworkImage(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type': 'multipart/form-data',
        'x-access-token': localStorage.getItem('token'),
        'x-user-id': localStorage.getItem('username')
      })
    };
    return this.http.post<any>(environment.api_Url + 'uploads/upload_artwork_image', data, httpOptions).pipe(
      map(value => {
        console.log(value)
        return value;
  })
    );
  }

  deleteArtwork(id) {
    return this.http.delete<any>(environment.api_Url + 'artworks/' + id, this.getHeaders()).pipe(
      map(value => value)
    );
  }

  removeArtworkImage(id) {
    return this.http.post<any>(environment.api_Url + 'uploads/remove_artwork_image', {'artwork': id}, this.getHeaders()).pipe(
      map(value => value)
    );
  }
  getCurrentDevice() {
    return this.http.get<any>(environment.api_Url + 'devices/current', this.getHeaders())
  }
  addToPlayPlaylist(id) {
    return this.http.post<any>(environment.api_Url + 'devices/queue_operations', {
      'playlist': id,
      'operation': 'push_front',
      'interval': 60,
      'device': getCurrentDeviceId(this.store),
    }, this.getHeaders())
      .pipe(
        map(data => data)
      );
  }
  
  addToPlayOnDevice(deviceId,obj) {
    return this.http.post<any>(environment.api_Url + 'devices/queue_operations', {
      ...obj,
      'operation': 'push_front',
      'device': deviceId
    }, this.getHeaders())
      .pipe(
        map(data => data)
      );
  }

  addToQueuePlaylist(id) {
    return this.http.post<any>(environment.api_Url + 'devices/queue_operations', {
      'playlist': id,
      'operation': 'push_back',
      'interval': 60,
      'device': getCurrentDeviceId(this.store),
    }, this.getHeaders())
      .pipe(
        map(data => data)
      );
  }

  addToPlayArtwork(id) {
    return this.http.post<any>(environment.api_Url + 'devices/queue_operations', {
      'artwork': id,
      'operation': 'push_front',
      'interval': 60,
      'device': getCurrentDeviceId(this.store),
    }, this.getHeaders())
      .pipe(
        map(data => data)
      );
  }

  searchArtist(request, page) {
    return this.http.get<any>(environment.api_Url + 'artists/search?' + request +
      '&p=display_name&p=bio&p=eras&p=movements&p=subjects&p=begin_year&p=end_year&p=nationality&p=cover_thumbnail_url&p=gender&page=' + page, this.getHeaders())
      .pipe(
        map(data => data)
      );
  }

  searchFeaturedArtist(request, page) {
    return this.http.get<any>(environment.api_Url + 'artists/featured?' + request +
      '&p=display_name&p=bio&p=eras&p=movements&p=subjects&p=begin_year&p=end_year&p=nationality&p=cover_thumbnail_url&p=gender&page=' + page, this.getHeaders())
      .pipe(
        map(data => data)
      );
  }

  searchMyArtist(request, page) {
    return this.http.get<any>(environment.api_Url + 'artists/search?userId=' + localStorage.getItem('username') + '&' + request +
      '&p=display_name&p=bio&p=eras&p=publication_status&p=movements&p=subjects&p=begin_year&p=end_year&p=nationality&p=cover_thumbnail_url&p=gender&page=' + page, this.getHeaders())
      .pipe(
        map(data => data)
      );
  }

  getArtworkTags() {
    return this.http.get<any>(environment.api_Url + 'artworks/stats/tag_counts', this.getHeaders())
      .pipe(
        map(data => data)
      );
  }

  searchArtwork(request, page) {
    return this.http.get<any>(environment.api_Url + 'artworks/search?' + request + '&totalMode=none&p=paid&p=medium&p=thumbnail&p=tags&p=accession&p=date&p=artist&p=classification&p=art_forms&p=art_types&p=source&p=credit&p=copyright&' +
      'p=painting_styles&p=details&p=dimensions&p=mediums&p=subjects&p=movements&p=orientation&page=' + page, this.getHeaders())
      .pipe(
        map(data => data)
      );
  }

  searchFeaturedArtwork(request, page) {
    return this.http.get<any>(environment.api_Url + 'artworks/featured?' + request + '&p=paid&p=medium&p=thumbnail&p=tags&p=accession&p=date&p=artist&p=classification&p=art_forms&p=art_types&p=source&p=credit&p=copyright&' +
      'p=painting_styles&p=details&p=dimensions&p=mediums&p=subjects&p=movements&p=orientation&page=' + page, this.getHeaders())
      .pipe(
        map(data => data)
      );
  }

  searchMyArtworks(request, page) {
    return this.http.get<any>(environment.api_Url + 'artworks/search/my?' + request +
      '&totalMode=none&p=paid&p=medium&p=thumbnail&p=tags&p=publication_status&p=accession&p=date&p=artist&p=classification&p=art_forms&p=art_types&p=source&p=credit&p=copyright&' +
      'p=painting_styles&p=details&p=dimensions&p=mediums&p=orientation&p=subjects&p=movements&page=' + page, this.getHeaders())
      .pipe(
        map(data => data)
      );
  }

  getMyArtworks(count) {
    return this.http.get<any>(environment.api_Url + 'artworks/search/my?limit='+count+'&totalMode=none&sort=-createdAt&p=paid&p=medium&p=thumbnail&p=tags&p=accession&p=date&p=artist&p=classification&p=art_forms&p=art_types&p=source&p=credit&p=copyright&' +
      'p=painting_styles&p=details&p=dimensions&p=mediums&p=orientation&p=subjects&p=movements&page=1', this.getHeaders())
      .pipe(
        map(data => data)
      );
  }
  getMyArtworksAll() {
    return this.http.get<any>(environment.api_Url + 'artworks/search/my?limit=350&page=1&totalMode=none', this.getHeaders())
      .pipe(
        map(data => data)
      );
  }
  rotateArtwork(id,dir){
    return this.http.post<any>(environment.api_Url + `artworks/${id}/transform`, {
      'operation' : 'rotate',
      'angle' : dir
  }, this.getHeaders())
      .pipe(
        map(data => data)
      );
  }

  getArtworkById(id) {
    return this.http.get<any>(environment.api_Url + 'artworks/' + id, this.getHeaders())
      .pipe(
        map(data => data)
      );
  }

  searchPlaylist(request, page) {
    return this.http.get<any>(environment.api_Url + 'playlists/search?' + request + '&page=' + page + '&p=paid&p=orientation&public=true&published=true&p=details&p=tags&p=public&p=shared&p=published&p=name&p=userId&p=aw_count&p=cover', this.getHeaders())
      .pipe(
        map(data => data)
      );
  }

  searchFeaturedPlaylist(request, page) {
    return this.http.get<any>(environment.api_Url + 'playlists/featured?' + request + '&page=' + page + '&p=paid&p=orientation&public=true&published=true&p=details&p=tags&p=public&p=shared&p=published&p=name&p=userId&p=aw_count&p=cover', this.getHeaders())
      .pipe(
        map(data => data)
      );
  }

  addToQueueArtwork(id) {
    return this.http.post<any>(environment.api_Url + 'devices/queue_operations', {
      'artwork': id,
      'operation': 'push_back',
      'interval': 60,
      'device': getCurrentDeviceId(this.store),
    }, this.getHeaders())
      .pipe(
        map(data => data)
      );
  }

  addPlaylistToFavourite(id) {
    return this.http.post<any>(environment.api_Url + 'preferences/add_playlist', {'playlist': id}, this.getHeaders())
      .pipe(
        map(data => data)
      );
  }

  addArtistoFavourite(id) {
    return this.http.post<any>(environment.api_Url + 'preferences/add_artist', {'artist': id}, this.getHeaders())
      .pipe(
        map(data => data)
      );
  }

  getArtsOfArtist(id) {
    return this.http.get<any>(environment.api_Url + 'artists/' + id + '/artworks?page=1&limit=50', this.getHeaders())
      .pipe(
        map(data => data)
      );
  }

  getArtists(page) {
    return this.http.get<any>(environment.api_Url + 'artists/all?page=' + page + '&limit=10&p=cover_thumbnail_url', this.getHeaders()).pipe(
      map(data => data)
    );
  }

  getMyArtists(page) {
    return this.http.get<any>(environment.api_Url + 'artists/my?page=' + page + '&limit=10&p=cover_thumbnail_url', this.getHeaders()).pipe(
      map(data => data)
    );
  }

  getArtistsByName(name) {
    return this.http.get<any>
    (environment.api_Url + 'artists/search?display_name=' + name + '&page=1&limit=20', this.getHeaders()).pipe(
      map(data => data)
    );
  }

  getMyArtistsByName(name) {
    return this.http.get<any>
    (environment.api_Url + 'artists/search?userId=' + localStorage.getItem('username') + '&display_name=' + name + '&page=1&limit=20', this.getHeaders()).pipe(
      map(data => data)
    );
  }

  getPlaylistsOfArtwork(id) {
    return this.http.get<any>
    (environment.api_Url + 'artworks/playlists/' + id, this.getHeaders()).pipe(
      map(data => data)
    );
  }
  getPlaylistsOfArtists(id) {
    return this.http.get<any>
    (environment.api_Url + `artists/${id}/playlists`, this.getHeaders()).pipe(
      map(data => data)
    );
  }
  removeDuplicateArtworks(body) {
    return this.http.post<any>(environment.api_Url + 'playlists/remove_artwork_by_index', body, this.getHeaders())
      .pipe(
        map(data => data)
      );
  }
  getFavArtworks() {
    return this.http.get<any>
    (environment.api_Url + 'preferences/artworks?limit=50&p=paid&p=medium&p=thumbnail&p=tags&p=publication_status&p=accession&p=date&p=artist&p=classification&p=art_forms&p=art_types&p=source&p=credit&p=copyright&p=painting_styles&p=details&p=dimensions&p=mediums&p=orientation&p=subjects&p=movements&page=1&format=docs', this.getHeaders()).pipe(
      map(data => data)
    );
  }
  removeFavArtwork(id) {
    return this.http.post<any>
    (environment.api_Url + 'preferences/remove_artwork',{artwork:id}, this.getHeaders()).pipe(
      map(data => data)
    );
  }
  addFavArtwork(id) {
    return this.http.post<any>
    (environment.api_Url + 'preferences/add_artwork',{artwork:id},this.getHeaders()).pipe(
      map(data => data)
    );
  }
  getPreferencesId() {
    return this.http.get<any>
    (environment.api_Url + 'preferences', this.getHeaders()).pipe(
      map(data => data)
    );
  }
}
