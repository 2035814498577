import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'durationSeconds' })
export class DurationSecondsPipe implements PipeTransform {
  transform(value?: number): string {
    if (!value) {
      return '';
    }
    value = Number(value);
    let weeks = Math.floor(value / 604800);
    value = value - (weeks * 86400);
    let days = Math.floor(value / 86400);
    value = value - (days * 86400);
    let hours = Math.floor(value / 3600);
    value = value - (hours * 3600);
    let minutes = Math.floor(value / 60);
    value = value - (minutes * 60);
    let seconds = value;
    let parts = [];
    if (weeks) {
      parts.push(`${weeks} weeks`);
    }
    if (days) {
      parts.push(`${days} days`);
    }
    if (hours) {
      parts.push(`${hours} hours`);
    }
    if (minutes) {
      parts.push(`${minutes} minutes`);
    }
    if (seconds) {
      parts.push(`${seconds} seconds`);
    }
    return parts.join(' ');
  }
}