import { createReducer, on } from '@ngrx/store';
import { CurrentUser, UserInfo } from "@models/currentuser.model";

import * as Actions from './currentuser.actions';

export const initialState: CurrentUser = {
    isLoggedIn: false,
    info: null,
    token: null
};


export const reducer = createReducer(
    initialState,
    on(Actions.loginAction, (state, { info, token }) => ({
        ...state,
        isLoggedIn: true,
        info: info,
        token: token
    })),
    on(Actions.tokenOnlyLoginAction, (state, { token }) => ({
        ...state,
        isLoggedIn: true,
        token: token
    })),
    on(Actions.loginWithRawDataAction, (state, { data }) => {
        let num_devices = 0;
        if (data.devices) {
            num_devices = data.devices.length;
        }
        let info: UserInfo = {
            email: data.email,
            first_name: data.first_name,
            last_name: data.last_name,
            role: data.role,
            tier: data.tier,
            num_devices: num_devices,
            verified: data.verified
        };
        if (data.google && data.google.id) {
            info.google = {
                provider: 'google',
                email: data.google.email,
                id: data.google.id,
                name: data.google.name,
            }
        }
        if (data.facebook && data.facebook.id) {
            info.facebook = {
                provider: 'facebook',
                email: data.facebook.email,
                id: data.facebook.id,
                name: data.facebook.name,
            }
        }
        if (data.apple && data.apple.id) {
            info.apple = {
                provider: 'apple',
                email: data.apple.email,
                id: data.apple.id,
                name: data.apple.name,
            }
        }
        state = {
            ...state,
            info: info
        };
        if (data.token){
            state = {
                ...state,
                isLoggedIn: true,
                token: data.token                
            }
        }
        if (data.expiresIn){
            let login_time = new Date();
            let expiry_time = new Date(login_time.getTime() + data.expiresIn * 1000);
            state = {
                ...state,
                login_time: login_time,
                expiresIn: data.expiresIn,
                expiry_time: expiry_time
            };
        }
        return state;
    }),
    on(Actions.logoutAction, state => ({
        ...state,
        isLoggedIn: false,
        login_time: null,
        expiry_time: null,
        token: null,
        info: null
    })),
    on(Actions.changeName, (state, { first_name, last_name }) => {
        let info: UserInfo = state.info;
        if (!info) {
            info = {};
        }
        info = {
            ...info,
            first_name,
            last_name
        };
        return {
            ...state,
            info
        };
    }),
    on(Actions.googleAccountUnlinked, (state) => {
        let info: UserInfo = state.info;
        info = {
            ...info,
            google: null
        };
        return {
            ...state,
            info
        };
    }),
    on(Actions.googleAccountLinked, (state, { data }) => {
        if (data && data.google && data.google.id) {
            let info: UserInfo = state.info;
            info = {
                ...info,
                google: {
                    provider: 'google',
                    email: data.google.email,
                    id: data.google.id,
                    name: data.google.name,
                }
            };
            return {
                ...state,
                info
            };
        }
        return state;
    }),
    on(Actions.appleAccountUnlinked, (state) => {
        let info: UserInfo = state.info;
        info = {
            ...info,
            apple: null
        };
        return {
            ...state,
            info
        };
    }),
    on(Actions.appleAccountLinked, (state, { data }) => {
        if (data && data.apple && data.apple.id) {
            let info: UserInfo = state.info;
            info = {
                ...info,
                apple: {
                    provider: 'apple',
                    email: data.apple.email,
                    id: data.apple.id,
                    name: data.apple.name,
                }
            };
            return {
                ...state,
                info
            };
        }
        return state;
    })
);

