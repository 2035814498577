import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BrowseService } from '../../services/browse.service';
import { PlaylistService } from '../../services/playlist.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-block',
  template: `
    <!--<div class="modal-header">-->
      <!--<h4 class="modal-title text-center" style="width: 100%">ADD TO PLAYLIST</h4>-->
      <!--<button type="button" class="close cls" (click)="bsModalRef.hide()">&times;</button>-->
    <!--</div>-->
    <div class="modal-header" style="border-bottom: 1px solid #c3c3c3;padding-bottom: 24px;">
      <h4 class="modal-title h4-heading">ADD TO PLAYLIST</h4>
      <div class="pull-right">
        <a href="javascript:void(0)" (click)="bsModalRef.hide()" class="close" data-dismiss="modal" style="padding: 33px"> <i
          class="material-icons">close</i> </a>
      </div>
    </div>
    <div class="modal-body">
      <div class="text-left">
        <ul class="list-group play playlist-list">
          <li class="list-group-item" *ngFor="let playlist of myPlaylists" data-dismiss="modal"
              (click)="addToPlaylist(playlist._id)" style="cursor: pointer">
            <a href="javascript:void(0)">
              <i class="material-icons pl-icon">playlist_play</i>{{playlist.name}}
            </a>
          </li>
        </ul>
      </div>

      <form [formGroup]="playlistName" class="create-playlist-field">
        <div class="playlist-input">
          <input type="text" formControlName="name" required placeholder="Create a new playlist">
          <button type="submit" [disabled]="playlistName.invalid" (click)="createPlaylist()"
                  data-dismiss="modal"
                  class="suc-msg playlist-create-btn text-center" style="cursor: pointer">
            <i class="material-icons play-bt">arrow_forward</i></button>
        </div>
      </form>
    </div>`,
  styleUrls: [`modal.scss`]
})
export class AddArtworksModalComponent implements OnInit {
  artist_id;
  artwork_id;
  type;
  playlistName: FormGroup;
  myPlaylists = [];

  constructor(
    private browseService: BrowseService,
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private playlistService: PlaylistService) {
  }

  createPlaylistForArtworks() {
    const request = {
      name: this.playlistName.value.name,
      cover: '',
      artworks: [this.artwork_id],
      tags: [],
      public: false
    };

    this.playlistService.createPlaylist(request)
      .subscribe(data => {
        console.log(data);
        this.playlistName.reset();
        this.snackbar.open(data.message, 'Success', {
          duration: 4000,
          horizontalPosition: 'left'
        });
        this.bsModalRef.hide();
      }, error1 => {
        this.snackbar.open(error1.error.message, 'Error', {
          duration: 4000,
          horizontalPosition: 'left'
        });
      });
  }

  createPlaylistForArtists() {
    const artworks = [];
    this.browseService.getArtsOfArtist(this.artist_id).subscribe(value => {
      for (let i = 0; i < value.docs.length; i++) {
        artworks.push(value.docs[i]._id);
      }

      const request = {
        name: this.playlistName.value.name,
        cover: '',
        artworks: artworks,
        tags: [],
        public: false
      };
      this.playlistService.createPlaylist(request)
        .subscribe(data => {
          this.snackbar.open('Playlist Created Succesfully', 'Success', {
            duration: 4000,
            horizontalPosition: 'left'
          });
          this.bsModalRef.hide();
        }, error1 => {
          this.snackbar.open(error1.error.message, 'Error', {
            duration: 4000,
            horizontalPosition: 'left'
          });
        });
    });
  }

  addToPlaylistForArtworks(playlistid) {
    const request = {
      'playlist': playlistid,
      'artworks': [this.artwork_id]
    };

    this.playlistService.addArtworkToPlaylist(request)
      .subscribe(data => {
        this.snackbar.open('Added successfully', 'Success', {
          duration: 4000,
          horizontalPosition: 'left'
        });
        this.bsModalRef.hide();
      }, error1 => {
        this.snackbar.open(error1.error.message, 'Error', {
          duration: 4000,
          horizontalPosition: 'left'
        });
      });
  }

  addToPlaylistForArtists(playlistid) {
    const artworks = [];
    this.browseService.getArtsOfArtist(this.artist_id).subscribe(value => {
      for (let i = 0; i < value.docs.length; i++) {
        artworks.push(value.docs[i]._id);
      }
      const request = {
        'playlist': playlistid,
        'artworks': artworks
      };

      this.playlistService.addArtworkToPlaylist(request)
        .subscribe(data => {
          this.snackbar.open('Added successfully', 'Success', {
            duration: 4000,
            horizontalPosition: 'left'
          });
          this.bsModalRef.hide();
        }, error1 => {
          this.snackbar.open(error1.error.message, 'Error', {
            duration: 4000,
            horizontalPosition: 'left'
          });
        });
    });
  }

  createPlaylist() {
    if (this.type === 'artwork') {
      this.createPlaylistForArtworks();
    } else {
      this.createPlaylistForArtists();
    }
  }

  addToPlaylist(playlistid) {
    if (this.type === 'artwork') {
      this.addToPlaylistForArtworks(playlistid);
    } else {
      this.addToPlaylistForArtists(playlistid);
    }
  }

  ngOnInit(): void {
    this.playlistName = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(4)]]
    });
    this.playlistService.getMyPlaylists().subscribe(value => {
      this.myPlaylists = value.docs;
    });
  }
}
