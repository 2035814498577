import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { NgxSpinnerService } from "ngx-spinner";
import { Store } from '@ngrx/store';
import { getCurrentDeviceId, getHttpOptions } from '@store/util';

@Injectable({ providedIn: 'root' })

export class UserService {

  constructor(private http: HttpClient,
    private spinner: NgxSpinnerService,
    private store: Store) {
  }
  showSpinner() {
    this.spinner.show()
  }
  hideSpinner() {
    this.spinner.hide()
  }

  getHeaders() {
    return getHttpOptions(this.store);
  }

  flushQueue() {
    return this.http.post<any>(environment.api_Url + 'devices/queue_operations', {
      'operation': 'flush',
      'device': getCurrentDeviceId(this.store),
    }, this.getHeaders())
      .pipe(
        map(data => data)
      );
  }

  setDeviceSettings(body) {
    return this.http.put<any>(environment.api_Url + 'devices', body, this.getHeaders())
      .pipe(
        map(data => data)
      );
  }

  setCurrentDevice(id) {
    return this.http.put<any>(environment.api_Url + 'devices/current', {
      'id': id,
    }, this.getHeaders())
      .pipe(
        map(data => data)
      );
  }

  setShuffle(id, value) {
    return this.http.put<any>(environment.api_Url + 'devices?id=' + id, { playback_shuffle: value }, this.getHeaders())
      .pipe(
        map(data => data)
      );
  }

  getDevices() {
    return this.http.get<any>(environment.api_Url + 'devices', this.getHeaders())
      .pipe(
        map(data => data)
      );
  }

  getDevicesSettings(id) {
    return this.http.get<any>(environment.api_Url + 'devices?id=' + id, this.getHeaders())
      .pipe(
        map(data => data)
      );
  }

  getDevicesStatus() {
    return this.http.get<any>(environment.api_Url + 'devices/connection_status', this.getHeaders())
      .pipe(
        map(data => data)
      );
  }

  changePassword(request) {
    return this.http.put<any>(environment.api_Url + 'users/password', {
      'old_password': request.oldPassword,
      'new_password': request.newPassword
    }, this.getHeaders())
      .pipe(
        map(data => data)
      );
  }
  //  *****************changeName**************************//
  changeName(body) {
    return this.http.put<any>(environment.api_Url + 'users/current_user_profile', body, this.getHeaders())
      .pipe(
        map(data => data)
      );
  }

  // ****************************** Preferences ********************************** //

  addPreferences(body, type) {
    return this.http.post<any>(environment.api_Url + 'preferences/' + type, body, this.getHeaders())
      .pipe(
        map(data => data)
      );
  }

  updatePreferences(body) {
    return this.http.put<any>(environment.api_Url + 'preferences/categories', body, this.getHeaders())
      .pipe(
        map(data => data)
      );
  }

  getPreferences() {
    return this.http.get<any>(environment.api_Url + 'preferences', this.getHeaders());
  }

  getCurrentUserSubscription() {
    return this.http.get<any>(environment.api_Url + 'users/current_user_subscription', this.getHeaders());
  }

  cancelUserSubscription(body = {}) {
    return this.http.patch<any>(environment.api_Url + 'subscriptions/stripe/cancel', body, this.getHeaders())
      .pipe(
        map(data => data)
      );
  }


  scheduleForDevice(body) {
    return this.http.post<any>(environment.api_Url + 'devices/scheduled_events', body, this.getHeaders())
      .pipe(
        map(data => data)
      );
  }

  EditScheduledeventForDevice(body) {
    return this.http.put<any>(environment.api_Url + 'devices/scheduled_events', body, this.getHeaders())
      .pipe(
        map(data => data)
      );
  }

  getMySchedule(id) {
    return this.http.get<any>(environment.api_Url + 'devices/scheduled_events?id=' + id, this.getHeaders())
      .pipe(
        map(data => data)
      );
  }

  getCategories() {
    return this.http.get<any>(environment.api_Url + 'public/categories').pipe(
      map(data => data)
    );
  }

  purchaseSubscription(body) {
    return this.http.post<any>(environment.api_Url + 'subscriptions', body, this.getHeaders())
      .pipe(
        map(data => data)
      );
  }

  deleteMySchedule(id, device_id) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token'),
        'x-user-id': localStorage.getItem('username')
      }),
      body: {
        'device': device_id,
        'event': id
      }
    };
    return this.http.delete<any>(environment.api_Url + 'devices/scheduled_events', options)
      .pipe(
        map(data => data)
      );
  }
  getCurrentUser() {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      }),
    };
    return this.http.get<any>(environment.api_Url + 'users/current_user_profile', options)
      .pipe(
        map(data => data)
      );
  }
  unlinkGoogleAccount() {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': localStorage.getItem('token')
      }),
    };
    return this.http.post<any>(environment.api_Url + 'auth/disconnect/google', null, options)
      .pipe(
        map(data => data)
      );
  }
  ///  devices/scheduled_events?id=5bc484d371dacf356c9fef10
}
