import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { BrowseService } from "../../services/browse.service";
import { NgxSpinnerService } from "ngx-spinner";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
interface ArtworkInfo {
  artworkId: string;
  title: string;
  details: string;
}
@Component({
  selector: "content-edit-modal",
  template: ` <a
      href="javascript:void(0)"
      class="text-right close myclosebtn"
      (click)="activeModal.close()"
    >
      <i class="material-icons" style="padding: 12px"> close </i>
    </a>
    <h4 class="text-center font modal-title">Edit Artwork Details</h4>
    <div class="myhr"></div>
    <div class="modal-body" style="padding-top:20px">
      <form
        style="display: flex; flex-direction: column; width: 100%;"
        [formGroup]="updateContent"
      >
        <mat-form-field>
          <mat-label>Title</mat-label>
          <input
            matInput
            formControlName="title"
            [(ngModel)]="artworkInfo.title"
          />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Details</mat-label>
          <textarea
            matInput
            formControlName="details"
            [(ngModel)]="artworkInfo.details"
          ></textarea>
        </mat-form-field>
      </form>
    </div>
    <div class="myhr"></div>
    <div
      *ngIf="updateContent.hasError('invalid')"
      class="text-center text-danger"
    >
      No Changes Were Detected
    </div>
    <div class="modal-footer">
      <button
        [disabled]="updateContent.invalid"
        (click)="modifyContent()"
        type="submit"
      >
        Upload
      </button>
    </div>`,
  styleUrls: [`modal.scss`],
})
export class EditArtworkComponent implements OnInit {
  protected updateContent: FormGroup;
  protected artworkId: string;
  @Input() protected artworkInfo: ArtworkInfo;
  @Output() protected passEntry: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private browseService: BrowseService,
    private snackBar: MatSnackBar,
    protected activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService
  ) {}

  isFieldValid(field: string) {
    return (
      !this.updateContent.get(field).valid &&
      this.updateContent.get(field).touched
    );
  }

  private constructorUpdatePayload(): { title?: string; details?: string } {
    let payload = {};
    if (this.updateContent.get("title").dirty) {
      payload["title"] = this.artworkInfo.title;
    }
    if (this.updateContent.get("details").dirty) {
      payload["details"] = this.artworkInfo.details;
    }
    return payload;
  }

  private displayUpdateState(message: string, type: string) {
    this.snackBar.open(message, type, {
      duration: 4000,
      verticalPosition: "bottom",
      horizontalPosition: "left",
    });
  }

  modifyContent() {
    const payload = this.constructorUpdatePayload();
    const artworkId = this.artworkInfo.artworkId;

    if (Object.keys(payload).length === 0) {
      this.updateContent.setErrors({ invalid: true });
      return;
    }

    this.spinner.show();
    this.browseService.updateArtworkDetails(artworkId, payload).subscribe({
      complete: () => {
        this.passEntry.emit(this.artworkInfo);
        this.displayUpdateState("Updated Successfully", "Success");
        this.spinner.hide();
      },
      error: (err) => {
        this.displayUpdateState(err.error.message, "Error");
        this.spinner.hide();
      },
    });
    this.activeModal.close(this.artworkInfo);
  }

  ngOnInit(): void {
    this.updateContent = this.fb.group({
      title: new FormControl(null, [Validators.required]),
      details: new FormControl(null, Validators.maxLength(400)),
    });
  }
}
