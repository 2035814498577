import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './guards/auth-guard.service';
import { LayoutComponent } from './layout/layout.component';
import { IndexComponent } from './layout/index.component';
// import { LayoutPartnerComponent } from './layout-partner/layout-partner.component';

// import { NavbarModule } from './navbar/navbar.module';


import { AnonguardService } from './guards/anon-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'index', pathMatch: 'full' },
  {
    path: '',
    component: LayoutComponent,
    children: [
      /// MAJOR PAGES
      {
        path: 'index',
        component: IndexComponent,
      },
      {
        path: 'browse',
        canActivate: [AuthGuardService],
        loadChildren: () => import('../app/collection/collection.module').then(m => m.CollectionModule),
      },
      {
        path: 'mycollection',
        canActivate: [AuthGuardService],
        loadChildren: () => import('../app/my-collection/my-collection.module').then(m => m.MyCollectionModule)
      },
      {
        path: 'mycrypto',
        canActivate: [AuthGuardService],
        loadChildren: () => import('../app/cryptocollection/cryptocollection.module').then(m => m.MyCryptoCollectionModule)
      },
      /// Account related pages
      {
        path: 'account',
        canActivate: [AuthGuardService],
        loadChildren: () => import('@pages/user/account/account.module').then(m => m.AccountModule)
      },
      {
        path: 'subscription',
        canActivate: [AuthGuardService],
        loadChildren: () => import('@pages/user/subscription/subscription.module').then(m => m.SubscriptionModule)
      },
      {
        path: 'preferences',
        canActivate: [AuthGuardService],
        loadChildren: () => import('@pages/user/preferences/preferences.module').then(m => m.PreferencesModule)
      },
      /// Support related pages
      {
        path: 'support',
        canActivate: [AuthGuardService],
        loadChildren: () => import('@pages/user/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'privacy',
        canActivate: [AuthGuardService],
        loadChildren: () => import('@pages/general/privacy/privacy.module').then(m => m.PrivacyModule)
      },
      /// Device related pages
      {
        path: 'devices',
        canActivate: [AuthGuardService],
        loadChildren: () => import('@modules/devices/devices.module').then(m => m.DevicesModule)
      },
      /// Additional pages
    ]
  },
  /// Login/Signup pages
  {
    path: 'login',
    //canActivate: [AnonguardService],
    loadChildren: () => import('@pages/user/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'register',
    canActivate: [AnonguardService],
    loadChildren: () => import('@pages/user/register/register.module').then(m => m.RegisterModule)
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full'
  }
];

@NgModule({
  declarations: [
  ],
  imports: [RouterModule.forRoot(routes, { useHash: true, enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
