import * as AppActions from './app.actions';
import { MatSnackBarHorizontalPosition } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import * as R from 'ramda';
import { getCurrentDevice, getCurrentDeviceId, getCurrentDeviceName, isDeviceOnline } from './util';
import { Artwork } from '@models/artwork.model';

export const Error = (store, message, duration: number = 4000, hpos: MatSnackBarHorizontalPosition = 'left') => {
    store.dispatch(AppActions.TOAST_MESSAGE(message, 'Error', duration, hpos));
}

export const Success = (store, message, duration: number = 4000, hpos: MatSnackBarHorizontalPosition = 'left') => {
    store.dispatch(AppActions.TOAST_MESSAGE(message, 'Success', duration, hpos));
}

export const handleBackendError = (store, error: HttpErrorResponse, duration: number = 4000, hpos: MatSnackBarHorizontalPosition = 'left') => {
    let body = error.error;
    let message = error.message;
    if (R.is(String, body)) {
        message = body;
    }
    else if (R.is(Object, body)) {
        if ('message' in body) {
            message = body.message;
        }
    }
    if (error.status == 500) {
        message = `${message} ${error.statusText}`;
    }
    store.dispatch(AppActions.TOAST_MESSAGE(message, 'Error', duration, hpos));
}


export const checkIfDeviceIsReady = (store) => {
    const device_id = getCurrentDeviceId(store);
    if (!device_id) {
        Error(store, 'No device has been selected. Please select a device for playback.');
        return false;
    }
    const device_name = getCurrentDeviceName(store);
    const online = isDeviceOnline(store);
    if (!online) {
        Error(store, `The device ${device_name} is not online. It cannot accept any commands.`);
        return;
    }
    return true;
}



export const canDeviceAccessArtwork = (store, artwork: Artwork) => {
    const device_id = getCurrentDeviceId(store);
    if (!device_id) {
        Error(store, 'No device has been selected. Please select a device for playback.');
        return false;
    }
    const device = getCurrentDevice(store);
    const device_name = device.device_name;
    const online = isDeviceOnline(store);
    if (!online) {
        Error(store, `The device ${device_name} is not online. It cannot accept any commands.`);
        return false;
    }
    if (artwork.paid) {
        if (!device.active_premium_subscription) {
            let msg = `The artwork ${artwork.title} is premium access` +
                ` and your device ${device_name} doesn't have premium access.` +
                `Please purchase a premium subscription for this device.` +
                `If you had purchased a premium subscription, please contact Canvia Support for activation.`;
            Error(store, msg);
            return false;
        }
    }
    return true;
}


export const canDeviceAccessPlaylist = (store, playlist) => {
    const device_id = getCurrentDeviceId(store);
    if (!device_id) {
        Error(store, 'No device has been selected. Please select a device for playback.');
        return false;
    }
    const device = getCurrentDevice(store);
    const device_name = device.device_name;
    const online = isDeviceOnline(store);
    if (!online) {
        Error(store, `The device ${device_name} is not online. It cannot accept any commands.`);
        return false;
    }
    if (playlist.paid) {
        if (!device.active_premium_subscription) {
            let msg = `The playlist ${playlist.name} is premium access` +
                ` and your device ${device_name} doesn't have premium access.` +
                `Please purchase a premium subscription for this device.` +
                `If you had purchased a premium subscription, please contact Canvia Support for activation.`;
            Error(store, msg);
            return false;
        }
    }
    return true;
}
