import { ActionReducerMap } from '@ngrx/store';

import { reducer as CurrentUserReducer } from './currentuser/currentuser.reducers';
import { reducer as DevicesReducer } from './device/device.reducers';
import { reducer as QueueReducer } from './device/queue.reducers';
import { reducer as UIReducer } from './ui/ui.reducers';
import {routerReducer} from '@ngrx/router-store';


import { AppState } from './app.state';
export const appReducer: ActionReducerMap<AppState> = {
    user: CurrentUserReducer,
    devices: DevicesReducer,
    queue: QueueReducer,
    router: routerReducer,
    ui: UIReducer,
};