import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import * as AppActions from './app.actions';
import * as UserActions from './currentuser/currentuser.actions';
import * as UserSelects from './currentuser/currentuser.selectors';
import { Router } from '@angular/router';
import { DevicesService } from '@services/devices.service';


@Injectable()
export class Appffects {
    logoutHandler$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.logoutAction),
            tap(action => {
                this.router.navigate(['/', 'login']);
            })
        ), { dispatch: false });

    appInitHandler$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.APP_INIT),
            withLatestFrom(this.store.select(UserSelects.selectIsLoggedIn)),
            tap(([action, isLoggedIn]) => {
                if (isLoggedIn) {
                    /// Trigger fetching details of my devices.
                    this.devices.get_my_devices().subscribe();
                }
            })
        ), { dispatch: false });

    constructor(private actions$: Actions,
        private router: Router,
        private store: Store,
        private devices: DevicesService) { }
}