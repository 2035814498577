

export interface QueueEntry {
    title?: string;
    is_playlist: boolean;
    id?: string;
    url?: string;
    count: number;
    index_1: number;
    index_2: number;
    inner: boolean,
    now_playing: boolean,
    tn?: string;
    or?: string;
    in_playlist: boolean;

    playlist?: string;
    playlist_id?: string;
    artwork?: string;
    artwork_id?: string;
};

export interface QueueModel {
    queue: QueueEntry[];
    is_playing: boolean;
    playback_duration: number;
    shuffle: boolean;
    total: number;
    now_playing_title: string;
    now_playing_id: string;
}



// Indicates if an entry is an artwork
export const is_artwork = (entry) => {
    if (!entry) {
        return false;
    }
    return 'aw' in entry;
}

// Indicates if an entry is a url based image/video
export const is_url = (entry) => {
    if (!entry) {
        return false;
    }
    return 'url' in entry;
}


// Indicates if an entry is a playlist
export const is_playlist = (entry) => {
    if (!entry) {
        return false;
    }
    return 'pl' in entry;
}


const flatten_queue = (q) => {
    let out: QueueEntry[] = [];
    // flatten the incoming queue
    for (var j = 0; j < q.length; ++j) {
        let entry = q[j];
        if (is_artwork(entry)) {
            out.push({
                title: entry.tt,
                is_playlist: false,
                id: entry.aw,
                count: entry.count,
                index_1: j,
                index_2: 0,
                inner: false,
                now_playing: entry.now_playing || false,
                tn: entry.tn,
                or: entry.or,
                in_playlist: false
            });
        }
        else if (is_url(entry)) {
            out.push({
                title: entry.tt,
                url: entry.url,
                is_playlist: false,
                count: entry.count,
                index_1: j,
                index_2: 0,
                inner: false,
                now_playing: entry.now_playing || false,
                or: entry.or,
                in_playlist: false
            });
        }
        else if (is_playlist(entry)) {
            // first push a playlist entry
            out.push({
                title: entry.name,
                is_playlist: true,
                id: entry.pl,
                index_1: j,
                index_2: -1,
                count: -1,
                inner: false,
                or: entry.or,
                in_playlist: true,
                now_playing: false
            });
            let aws = entry.artworks;
            for (var k = 0; k < aws.length; ++k) {
                let aw = aws[k];
                out.push({
                    title: aw.tt,
                    is_playlist: false,
                    id: aw.aw,
                    count: aw.count,
                    index_1: j,
                    index_2: k,
                    inner: true,
                    now_playing: aw.now_playing || false,
                    tn: aw.tn,
                    or: aw.or,
                    in_playlist: true
                });
            }
            continue;
        }
    }
    return out;
};

export const flatten = (payload) => {
    let model: QueueModel = {
        queue: flatten_queue(payload.queue),
        is_playing: payload.is_playing,
        playback_duration: payload.playback_duration,
        shuffle: payload.shuffle,
        total: payload.total,
        now_playing_title: payload.now_playing_title,
        now_playing_id: payload.now_playing_id,
    };
    return model;
};
