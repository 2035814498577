import { map, take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { HttpHeaders } from '@angular/common/http';
import * as Device from '@store/device/device.selectors';
import * as Queue from '@store/device/queue.selectors';
import * as User from '@store/currentuser/currentuser.selectors';


const getValue = (store: Store, selector) => {
    let result;
    store.pipe(select(selector), take(1)).subscribe(v => {
        result = v;
    });
    return result;
}

export const getAccessToken = (store: Store) => {
    let token = '';
    store.pipe(select(User.selectToken), take(1)).subscribe(v => {
        token = v;
    });
    return token;
}


export const getHttpOptions = (store: Store) => {
    let token = getAccessToken(store);
    const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-access-token': token,
        })
    };
    return httpOptions;
}

export const getUserProfile = (store: Store) => {
    return getValue(store, User.selectProfile);
}

export const getEmail = (store: Store) => {
    return getValue(store, User.selectEmail);
}

export const getName = (store: Store) => {
    return getValue(store, User.selectName);
}

export const isGoogleLinked = (store: Store) => {
    return getValue(store, User.selectIsGoogleLinked);
}


export const getCurrentDeviceId = (store: Store) => {
    return getValue(store, Device.selectCurrentDeviceId);
}

export const getCurrentDeviceName = (store: Store) => {
    return getValue(store, Device.selectCurrentDeviceName);
}

export const getCurrentDevice = (store: Store) => {
    return getValue(store, Device.selectCurrentDevice);
}

export const isDeviceOnline = (store: Store) => {
    return getValue(store, Device.selectIsDeviceOnline);
}

export const isDeviceOffline = (store: Store) => {
    return !(isDeviceOnline(store));
}

export const getAllDevices = (store: Store) => {
    return getValue(store, Device.selectAllDevices);
}

export const isQueueShuffle = (store: Store) => {
    return getValue(store, Queue.selectIsShuffle);
}
