import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Router, RouterState, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from "rxjs/operators";
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { logoutAction } from '@store/currentuser/currentuser.actions';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private store: Store) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      tap(
        event => this.handleResponse(req, event),
        error => this.handleError(req, error)
      )
    );
  }

  handleResponse(req: HttpRequest<any>, event) {
  }

  handleError(req: HttpRequest<any>, err: any) {
    const state: RouterState = this.router.routerState;
    const snapshot: RouterStateSnapshot = state.snapshot;
    if (err instanceof HttpErrorResponse) {
      if (err.status === 401) {
        this.snackbar.open('Your login session has expired. Please login again.', 'Error', {
          duration: 6000,
          horizontalPosition: 'left'
        });
        this.store.dispatch(logoutAction());
        console.log(this.route);
        this.router.navigate(['login'], {
          queryParams: {
            nextTo: snapshot.url
          }
        });
      }
    }
  }
}
