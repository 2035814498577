import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { AddArtworksModalComponent } from './AddArtworksModal';
import { ScheduleModalComponent } from './ScheduleModal';
import { EditArtworkComponent } from './EditArtwork';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DevicesService } from '@services/devices.service';
import { getCurrentDeviceName } from '@store/util';
import * as DeviceSelects from '@store/device/device.selectors';
import * as Toaster from '@store/toaster';
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

// @ts-ignore
// @ts-ignore
// @ts-ignore
@Component({
  selector: "app-block",
  template: `
    <div class="artWork-modal" role="document">
      <div class="modal-body">
        <div class="">
          <img
            class="modal-close"
            (click)="bsModalRef.hide()"
            src="./assets/newImgs/close.svg"
            alt=""
            srcset=""
          />
          <div
            [class.row]="orientation === 'v'"
            style="display: flex; flex-direction: row"
          >
            <div [class.col-md-6]="orientation === 'v'" style="width: 50%">
              <img
                src="{{ thumbnail }}"
                alt=""
                [class.imgPor]="orientation === 'v'"
                srcset=""
              />
            </div>
            <div [class.col-md-6]="orientation === 'v'" style="width: 50%">
              <div class="container modal-info">
                <div class="row p-0 m-0">
                  <div class="col m-0 p-0">
                    <h5>{{ title }}</h5>
                    <div class="d-flex" style="flex-wrap: wrap;">
                      <h6 class="f-17 mr-1 mt-1 mb-2">{{ artist }}</h6>
                    </div>
                    <p class="pb-3">{{ details }}</p>
                    <div class="row art-info pb-3" *ngIf="date">
                      <div class="col-3 info-key">Date</div>
                      <div class="col-9 info-value">{{ date }}</div>
                    </div>
                    <div class="row art-info py-3" *ngIf="artist">
                      <div class="col-3 info-key">Artist</div>
                      <div class="col-9 info-value">{{ artist }}</div>
                    </div>
                    <div class="row art-info py-3" *ngIf="credit">
                      <div class="col-3 info-key">Credit</div>
                      <div class="col-9 info-value">{{ credit }}</div>
                    </div>
                    <div class="row art-info py-3" *ngIf="medium">
                      <div class="col-3 info-key">Medium</div>
                      <div class="col-9 info-value">{{ medium }}</div>
                    </div>
                    <div class="row art-info py-3" *ngIf="classification">
                      <div class="col-3 info-key">Classification</div>
                      <div class="col-9 info-value length3">
                        {{ classification }}
                      </div>
                    </div>
                    <div class="row art-info py-3" *ngIf="tieredStatus">
                      <div class="col-3 info-key">Access Tier</div>
                      <div class="col-9 info-value length0">
                        {{ tieredArray[tieredStatus] }}
                      </div>
                    </div>
                    <div class="row art-info py-3" *ngIf="price">
                      <div class="col-3 info-key">Price (USD)</div>
                      <div class="col-9 info-value">{{ price }}</div>
                    </div>
                    <div class="row art-info py-3" *ngIf="playlists.length > 0">
                      <div class="col-3 info-key">PLAYLISTS</div>
                      <div class="col-9 info-value">
                        <small
                          *ngFor="let playlist of playlists; let i = index"
                          (click)="
                            goToPlaylist(
                              playlist._id,
                              playlist.details,
                              playlist.name
                            )
                          "
                          >{{ playlist.name
                          }}<span *ngIf="i + 1 !== playlists.length"></span
                        ></small>
                      </div>
                    </div>
                    <div
                      class="container mr-btm card-tag mt-2"
                      style="padding: 0px"
                    >
                      <span
                        (click)="showArtworkswithTag(tag)"
                        class="btn custom-btn"
                        *ngFor="let tag of tags"
                      >
                        {{ tag }}
                      </span>
                      <span
                        (click)="showArtworkswithTag(tag)"
                        class="btn custom-btn"
                        *ngFor="let tag of painting_styles"
                      >
                        {{ tag }}
                      </span>
                      <span
                        (click)="showArtworkswithTag(tag)"
                        class="btn  custom-btn"
                        *ngFor="let tag of art_forms"
                      >
                        {{ tag }}
                      </span>
                      <span
                        (click)="showArtworkswithTag(tag)"
                        class="btn custom-btn"
                        *ngFor="let tag of art_types"
                      >
                        {{ tag }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="d-flex option text-center justify-content-center mt-1 mb-2"
              >
                <button
                  *ngIf="paid === false"
                  type="button"
                  class="btn btn-default btn-link"
                  matTooltip="Edit"
                  (click)="openEditModal(id, title, details)"
                >
                  <i class="material-icons">edit</i>
                </button>
                <button
                  type="button"
                  class="btn btn-success btn-link"
                  (click)="openAddModal(id)"
                  matTooltip="Add to a Playlist"
                >
                  <i class="material-icons model_link-popup">playlist_add</i>
                </button>
                <!--  -->
                <button
                  *ngIf="online$ | async"
                  type="button"
                  class="btn btn-default btn-link"
                  (click)="addArtworkToPlay(id)"
                  matTooltip="Play"
                >
                  <i class="material-icons play-bt">play_arrow</i>
                </button>
                <button
                  *ngIf="online$ | async"
                  type="button"
                  class="btn btn-danger btn-link"
                  (click)="addArtworkToQueue(id)"
                  matTooltip="Queue"
                >
                  <img style="width:auto;" src="./assets/newImgs/music.svg" />
                </button>
                <button
                  *ngIf="online$ | async"
                  type="button"
                  class="btn btn-info btn-link"
                  (click)="showScheduleModal(id, 'artwork')"
                  matTooltip="Schedule"
                >
                  <img
                    style="width:auto;"
                    src="./assets/newImgs/calender.svg"
                  />
                </button>
                <!--  -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: [`showArtwork.scss`],
})
export class ShowArtworkModalComponent implements OnInit {
  // Current device status
  online$ = this.store.select(DeviceSelects.selectIsDeviceOnline);
  id;
  thumbnail = "";
  tags = [];
  art_types = [];
  art_forms = [];
  title;
  date;
  artist;
  accession;
  dimensions;
  credit;
  medium;
  painting_styles = [];
  classification;
  details;
  tieredStatus = null;
  price = null;
  modalRef: BsModalRef | NgbModalRef;
  playlists = [];
  orientation = "";
  source: "";
  paid: boolean;
  tieredArray = [
    "All users can access it",
    "Premium Tier users can access it without paying separately for it",
    "Free Tier users can access it only after paying separately for it",
    "All users need to pay separately to access it",
  ];

  constructor(
    public bsModalRef: BsModalRef,
    private devicesService: DevicesService,
    private router: Router,
    private modalService: BsModalService,
    private store: Store,
    private activeModal: NgbModal
  ) {}

  showArtworkswithTag(tag) {
    localStorage.setItem("artworktag", JSON.stringify([tag]));
    this.router.navigate(["browse", "artworks"], {
      queryParams: { source: "browse", tag: tag },
    });
    this.bsModalRef.hide();
  }

  addArtworkToPlay(id) {
    if (!Toaster.checkIfDeviceIsReady(this.store)) {
      return;
    }
    this.devicesService.push_front_artwork(id).subscribe(
      (data) => {
        let device_name = getCurrentDeviceName(this.store);
        let msg = `Initiating playback on ${device_name}`;
        Toaster.Success(this.store, msg);
      },
      (error) => {
        Toaster.handleBackendError(this.store, error);
      }
    );
  }

  goToPlaylist(id, details, name) {
    this.bsModalRef.hide();
    this.router.navigate(["browse", "list", "playlist", id, "browse"], {
      queryParams: {
        public: false,
        // list_type: 'playlist',
        // list_id: id,
        // name: name,
        details: details,
        // source: 'browse'
      },
    });
  }
  openAddModal(id) {
    this.modalRef = this.modalService.show(AddArtworksModalComponent);
    this.modalRef.content.type = "artwork";
    this.modalRef.content.artwork_id = this.id;
  }

  addArtworkToQueue(id) {
    if (!Toaster.checkIfDeviceIsReady(this.store)) {
      return;
    }
    this.devicesService.push_back_artwork(id).subscribe(
      (data) => {
        let device_name = getCurrentDeviceName(this.store);
        let msg = `Enqueuing on ${device_name}`;
        Toaster.Success(this.store, msg);
      },
      (error) => {
        Toaster.handleBackendError(this.store, error);
      }
    );
  }

  showScheduleModal(id, type) {
    this.modalRef = this.modalService.show(ScheduleModalComponent);
    this.modalRef.content.type = type;
    this.modalRef.content.play_art_id = id;
  }

  openEditModal(id: string, title: string, details: string) {
    this.modalRef = this.activeModal.open(EditArtworkComponent, {
      modalDialogClass: "gray modal-lg",
    });

    this.modalRef.componentInstance.artworkInfo = {
      artworkId: id,
      title: title,
      details: details,
    };

    this.modalRef.result.then((updatedInfo) => {
      if (updatedInfo) {
        this.title = updatedInfo.title;
        this.details = updatedInfo.details;
      }
    });
  }
  ngOnInit(): void {}
}
