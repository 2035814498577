import { createAction, props } from '@ngrx/store';
import { UserInfo } from "@models/currentuser.model";


/// When jwt token and user information are both received.
export const loginAction = createAction(
    '[Auth API] Login',
    props<{ info: UserInfo, token: string }>()
);

export const tokenOnlyLoginAction = createAction(
    '[Auth API] Login Token Only',
    props<{token: string }>()
);

/// Update user info from raw user profile data received from server.
export const loginWithRawDataAction = createAction(
    '[Auth API] Login Raw',
    props<{ data: any }>()
);

export const logoutAction = createAction(
    '[Auth API] Logout'
);


export const changeName = createAction(
    '[Profile] changeName',
    props<{ first_name: string, last_name: string }>()
);


export const googleAccountUnlinked = createAction(
    '[Profile] googleAccountUnlinked',
);

export const googleAccountLinked = createAction(
    '[Profile] googleAccountLinked',
    props<{ data: any }>()
);

export const appleAccountUnlinked = createAction(
    '[Profile] appleAccountUnlinked',
);

export const appleAccountLinked = createAction(
    '[Profile] appleAccountLinked',
    props<{ data: any }>()
);
