import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';

import * as AppActions from './app.actions';

@Injectable()
export class ToastEffects {
    message$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AppActions.TOAST_MESSAGE),
            tap(action => {
                console.log(action.message, action.action);
                this.snackBar.open(action.message, action.action, {
                    duration: action.duration,
                    horizontalPosition: action.hpos
                });
            })
        ), { dispatch: false });

    constructor(private actions$: Actions,
        private snackBar: MatSnackBar,) { }
}