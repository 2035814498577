import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";

export interface IDLabel {
    id: string;
    label: string;
}

export interface IDLabels extends EntityState<IDLabel> {

};

/// High level storage for all UI related stuff
export interface UI {

    id_to_label_map: IDLabels;
};

export const idToLabelAdaptor: EntityAdapter<IDLabel> =
    createEntityAdapter<IDLabel>({
    });
