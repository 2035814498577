import {
  APP_INITIALIZER,
  ErrorHandler,
  NgModule,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

/// Component Modules
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

// NgRx
import { StoreModule, ActionReducer, MetaReducer } from "@ngrx/store";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { appReducer } from "./store/reducers";
import { localStorageSync } from "ngrx-store-localstorage";
export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: ["user", "devices", "queue"],
    rehydrate: true,
  })(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];
import { EffectsModule } from "@ngrx/effects";
import { DeviceEffects } from "@store/device/device.effects";
import { SocketIOEffects } from "@store/device/sio.effects";
import { ToastEffects } from "@store/toast.effects";
import { Appffects } from "@store/app.effects";
import { OAuthffects } from "@store/oauth.effects";

// Angular Material
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatNativeDateModule } from "@angular/material/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSliderModule } from "@angular/material/slider";
import { MatSnackBarModule } from "@angular/material/snack-bar";

import { MDBBootstrapModule } from "angular-bootstrap-md";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

// Angular Bootstrap Extensions
import { ModalModule } from "ngx-bootstrap/modal";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { CarouselModule } from "ngx-bootstrap/carousel";

import { NgxSpinnerModule } from "ngx-spinner";

import { DragulaModule } from "ng2-dragula";

import { LazyLoadImageModule } from "ng-lazyload-image";

// for Router import:
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
// for Core import:
import { LoadingBarModule } from "@ngx-loading-bar/core";

/// Google analytics support
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from "ngx-google-analytics";

import "hammerjs";

// Local components
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NavbarModule } from "./navbar/navbar.module";
import { SharedModule } from "@shared/shared.module";

// Layouts
import { LayoutComponent } from "./layout/layout.component";
// import { LayoutPartnerComponent } from './layout-partner/layout-partner.component';
// Footer
import { PlaylistBarModule } from "./playlist-bar/playlist-bar.module";

// Backend request processing
import { RequestInterceptor } from "./request-interceptor";

// All the modal dialogs
import { ShowArtworkModalComponent } from "./collection/modals/ShowArtworkModal";
import { ShowArtistModalComponent } from "./collection/modals/ShowArtistModal";
import { ScheduleModalComponent } from "./collection/modals/ScheduleModal";
import { EditScheduledEventComponent } from "./collection/modals/EditScheduledEventModal";
import { PersonelUploadModalComponent } from "./collection/modals/PersonelUploadModal";
import { ScheduleModalNabsComponent } from "./collection/modals/ScheduleModalNavbar";
import { AddArtworksModalComponent } from "./collection/modals/AddArtworksModal";
import { ShowArtworkModalPortraitComponent } from "./collection/modals/ShowArtworkModalPortrait";
import { PlaylistUploadImagesComponent } from "./collection/modals/PlaylistUploadImages";

// Sentry
import * as Sentry from "@sentry/angular-ivy";
import { Router } from "@angular/router";
import { EditArtworkComponent } from "./collection/modals/EditArtwork";


@NgModule({
  declarations: [
    AppComponent,

    // Layout components
    LayoutComponent,
    // LayoutPartnerComponent,

    // Footer

    // Modal dialogs
    ShowArtworkModalComponent,
    ShowArtistModalComponent,
    ScheduleModalComponent,
    EditArtworkComponent,
    PersonelUploadModalComponent,
    ScheduleModalNabsComponent,
    EditScheduledEventComponent,
    ShowArtworkModalPortraitComponent,
    AddArtworksModalComponent,
    PlaylistUploadImagesComponent,
  ],
  imports: [
    /// Standard Angular Modules
    BrowserModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    // NgRx support
    StoreModule.forRoot(appReducer, { metaReducers }),
    EffectsModule.forRoot([
      DeviceEffects,
      SocketIOEffects,
      ToastEffects,
      Appffects,
      OAuthffects,
    ]),
    StoreRouterConnectingModule.forRoot(),

    // Angular Material Modules
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatInputModule,
    MatRadioModule,
    MatSliderModule,
    MatProgressSpinnerModule,

    // Angular Bootstrap MD
    MDBBootstrapModule.forRoot(),
    // NGX-BOOTSTRAP components
    CarouselModule.forRoot(),
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    ProgressbarModule.forRoot(),
    TimepickerModule.forRoot(),

    // Modules from other third party libraries
    DragulaModule.forRoot(),
    LazyLoadImageModule,
    NgbModule,
    LoadingBarRouterModule,
    LoadingBarModule,

    // Application Modules
    AppRoutingModule,
    NavbarModule,
    PlaylistBarModule,
    SharedModule,

    /// Google analytics integration
    NgxGoogleAnalyticsModule.forRoot("G-3L51PWJ45D"),
    NgxGoogleAnalyticsRouterModule,
  ],
  // schemas: [NO_ERRORS_SCHEMA],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    { provide: "googleTagManagerId", useValue: "GTM-PJ2N369" },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [],
})
export class AppModule {}
