import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { selectBreadcrumbs } from '@store/ui/ui.selectors';
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  crumbs$ = this.store.select(selectBreadcrumbs);

  @Input() symbol = ' / ';

  constructor(private store: Store) { }

  ngOnInit(): void {
  }

}
