import { createReducer, on } from '@ngrx/store';

import { UI, IDLabel, idToLabelAdaptor } from '@models/ui.model';
import * as Actions from './ui.actions';

const initialState: UI = {
    id_to_label_map: idToLabelAdaptor.getInitialState()
};

export const reducer = createReducer(
    initialState,
    on(Actions.setOneLabelForIDEntry, (state, { id, label }) => {
        let id_to_label_map = state.id_to_label_map;
        id_to_label_map = idToLabelAdaptor.setOne({
            id: id,
            label: label
        }, id_to_label_map);
        return {
            ...state,
            id_to_label_map
        };
    }),
    on(Actions.setManyLabelForIDEntry, (state, { entries }) => {
        let id_to_label_map = state.id_to_label_map;
        id_to_label_map = idToLabelAdaptor.setMany(entries, id_to_label_map);
        return {
            ...state,
            id_to_label_map
        };
    }),

);