import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { environment } from '@env/environment';
import * as Util from '@store/util';

import { ValueType } from '@models/types';

@Injectable({
  providedIn: 'root'
})

export class BackendService {
  constructor(
    private http: HttpClient,
    private store: Store) { }

  // Authenticated GET requests with query parameters
  get<T>(url, query: {
    [param: string]: ValueType;
  } = undefined) {

    url = `${environment.api_Url}${url}`;
    let options = Util.getHttpOptions(this.store);
    if (query) {
      let params = new HttpParams({
        fromObject: query
      });
      options['params'] = params;
    }
    return this.http.get<T>(url, options);
  }

  // Authenticated DELETE requests with query parameters
  delete(url, body: {
    [param: string]: ValueType;
  } = undefined, query: {
    [param: string]: ValueType;
  } = undefined) {

    url = `${environment.api_Url}${url}`;
    let options = Util.getHttpOptions(this.store);
    if (query) {
      let params = new HttpParams({
        fromObject: query
      });
      options['params'] = params;
    }
    if (body){
      options['body'] = body;
    }
    return this.http.delete(url, options);
  }

  // Authenticated PUT requests with body and query parameters
  put(url, body: {
    [param: string]: ValueType;
  }, query: {
    [param: string]: ValueType;
  } = undefined) {

    url = `${environment.api_Url}${url}`;
    let options = Util.getHttpOptions(this.store);
    if (query) {
      let params = new HttpParams({
        fromObject: query
      });
      options['params'] = params;
    }
    return this.http.put(url, body, options);
  }

  // Authenticated POST requests with body and query parameters
  post<T>(url, body: {
    [param: string]: ValueType;
  }, query: {
    [param: string]: ValueType;
  } = undefined) {

    url = `${environment.api_Url}${url}`;
    let options = Util.getHttpOptions(this.store);
    if (query) {
      let params = new HttpParams({
        fromObject: query
      });
      options['params'] = params;
    }
    return this.http.post<T>(url, body, options);
  }
}
