import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-schedule',
  template: `
    <div class="modal-content">
      <a href="javascript:void(0)" class="text-right close myclosebtn" (click)="bsModalRef.hide()">
        <i class="material-icons"> close </i>
      </a>
      <h4 class="text-center  modal-title font" *ngIf="type=='playlist'"> Schedule Playlist </h4>
      <h4 class="text-center  modal-title font" *ngIf="type=='artwork'"> Schedule Artwork </h4>
      <div class="myhr"></div>
      <div class="modal-body" style="padding-top:20px">

        <div class="row">
          <label for="" class="col-sm-3 lh60"> Device</label>
          <div class="col-sm-9">
            <div class="abc">
              <mat-form-field>
                <mat-select placeholder="Select Device" [(ngModel)]="device_id">
                  <mat-option *ngFor="let device of devices"
                              [value]="device.device_id">{{device.device_name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row">
          <label for="" class="col-sm-3 my-label">Time</label>
          <div class="col-sm-9">
            <div class="form-group">
              <timepicker [(ngModel)]="scheduleTime" (isValid)="isValid = $event"></timepicker>
              <pre *ngIf="!isValid" class="text-danger">Please enter a valid time</pre>
            </div>
          </div>
        </div>

        <div class="row">
          <label for="" class="col-sm-3 lh70">Frequency</label>
          <div class="col-sm-9">
            <div class="form-group">
              <mat-form-field>
                <mat-select placeholder="Please Select" [(ngModel)]="scheduleDayPlay">
                  <mat-option value="everyday">Everyday</mat-option>
                  <mat-option value="weekdays">Every weekday (Mon-Fri)</mat-option>
                  <mat-option value="specific-day">On specific days in a week</mat-option>
                  <mat-option value="specific-date">On a specific date</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row day-checkbox" *ngIf="scheduleDayPlay=='specific-day'">
          <label class="col-sm-3 lh25 label-checkbox">Select Days</label>
          <div class="col-sm-9 checkbox-radios">
            <div class="form-group" style="margin-left: 2px;margin-right: -8px">
              <div class="form-check form-check-inline">
                <label class="form-check-label">
                  <input class="form-check-input" type="checkbox" value="Mon" (change)="change($event)"> Mon
                  <span class="form-check-sign">
												<span class="check"></span>
											</span>
                </label>
              </div>
              <div class="form-check form-check-inline">
                <label class="form-check-label">
                  <input class="form-check-input" type="checkbox" value="Tue" (change)="change($event)"> Tue
                  <span class="form-check-sign">
												<span class="check"></span>
											</span>
                </label>
              </div>
              <div class="form-check form-check-inline">
                <label class="form-check-label">
                  <input class="form-check-input" type="checkbox" value="Wed" (change)="change($event)"> Wed
                  <span class="form-check-sign">
												<span class="check"></span>
											</span>
                </label>
              </div>
              <div class="form-check form-check-inline">
                <label class="form-check-label">
                  <input class="form-check-input" type="checkbox" value="Thu" (change)="change($event)"> Thu
                  <span class="form-check-sign">
												<span class="check"></span>
											</span>
                </label>
              </div>
              <div class="form-check form-check-inline">
                <label class="form-check-label">
                  <input class="form-check-input" type="checkbox" value="Fri" (change)="change($event)"> Fri
                  <span class="form-check-sign">
												<span class="check"></span>
											</span>
                </label>
              </div>
              <div class="form-check form-check-inline">
                <label class="form-check-label">
                  <input class="form-check-input" type="checkbox" value="Sat" (change)="change($event)"> Sat
                  <span class="form-check-sign">
												<span class="check"></span>
											</span>
                </label>
              </div>
              <div class="form-check form-check-inline">
                <label class="form-check-label">
                  <input class="form-check-input" type="checkbox" value="Sun" (change)="change($event)"> Sun
                  <span class="form-check-sign">
												<span class="check"></span>
											</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="row pickdate" *ngIf="scheduleDayPlay=='specific-date'">
          <label for="" class="col-sm-3 lh60"> Pick Date</label>
          <div class="col-sm-9">
            <mat-form-field>
              <input (keydown)="false" (click)="picker.open()" matInput [(ngModel)]="scheduleDate" [min]="minDate" [matDatepicker]="picker"
                     placeholder="Choose a date">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div  style="width:100%" class="myhr mt-3"></div>
        <div class="modal-footer">
          <div class="col-md-12 text-center">
            <button [disabled]="!isValid" type="submit" (click)="schedule()"
                    class="btn btn-success2">Schedule
            </button>
            <h4 *ngIf="freq" class="col-md-12 text-danger">
              Please Select Frequency
            </h4>
          </div>
        </div>
      </div>
    </div>`,
  styleUrls: [`scheduleModel.scss`]
})
export class ScheduleModalComponent implements OnInit {
  isValid = true;
  scheduleDayPlay;
  minDate = new Date();
  play_art_id;
  type = '';
  freq = false;
  device_id = '';
  scheduleDays = [];
  scheduleTime = '';
  scheduleDate = '';
  devices;
  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  constructor(
    public bsModalRef: BsModalRef,
    private snackbar: MatSnackBar,
    public userService: UserService) {
  }

  change(event) {
    if (event.target.checked) {
      this.scheduleDays.push(event.target.defaultValue);
    } else {
      for (let i = this.scheduleDays.length - 1; i >= 0; i--) {
        if (this.scheduleDays[i] === event.target.defaultValue) {
          this.scheduleDays.splice(i, 1);
        }
      }
    }
  }

  callScheduleApi(body) {
    this.freq = false;
    this.userService.scheduleForDevice(body).subscribe(value => {
      this.snackbar.open('The scheduled event has been created', 'Success', {
        duration: 4000,
        horizontalPosition: 'left'
      });
      this.bsModalRef.hide();
    }, error1 => {
      this.snackbar.open(error1.error.message, 'Error', {
        duration: 4000,
        horizontalPosition: 'left'
      });
    });
  }

  schedule() {
    const time = new Date(this.scheduleTime);
    const date = new Date(this.scheduleDate);
    console.log(time.getHours(), time.getMinutes(), date.getDay(), date.getMonth(), date.getFullYear(), date.getDate());
    if (this.scheduleDayPlay === 'everyday') {
      const body = {
        'event_type': this.type,
        [this.type]: this.play_art_id,
        'device': this.device_id,
        'hour': time.getHours(),
        'minute': time.getMinutes(),
        'days_of_week': ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
      };
      this.callScheduleApi(body);
    } else if (this.scheduleDayPlay === 'weekdays') {
      const body = {
        'event_type': this.type,
        [this.type]: this.play_art_id,
        'device': this.device_id,
        'hour': time.getHours(),
        'minute': time.getMinutes(),
        'days_of_week': ['Mon', 'Tue', 'Wed', 'Thu', 'Fri']
      };
      this.callScheduleApi(body);
    } else if (this.scheduleDayPlay === 'specific-day') {
      const body = {
        'event_type': this.type,
        [this.type]: this.play_art_id,
        'device': this.device_id,
        'hour': time.getHours(),
        'minute': time.getMinutes(),
        'days_of_week': this.scheduleDays
      };
      this.callScheduleApi(body);
    } else if (this.scheduleDayPlay === 'specific-date') {
      const body = {
        'event_type': this.type,
        [this.type]: this.play_art_id,
        'device': this.device_id,
        'hour': time.getHours(),
        'minute': time.getMinutes(),
        'days_of_month': [date.getDate()],
        'months': [this.months[date.getMonth()]],
        'years': [date.getFullYear()]
      };
      this.callScheduleApi(body);
    } else {
      this.freq = true;
    }
  }

  ngOnInit(): void {
    this.userService.getDevices().subscribe(value => {
      this.devices = value;
      if (this.devices.length === 1) {
        this.device_id = this.devices[0].device_id;
      }
    });
  }
}
