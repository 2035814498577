<app-navbar *ngIf="isLoggedIn$ | async"></app-navbar>
<!--<ol class="breadcrumb">
  <li class="breadcrumb-item"><a routerLink="/home">Home</a></li>
  <li class="breadcrumb-item active"
      *ngFor="let path of currentUrlPath; let i = index">
    {{path}}
  </li>
</ol>-->

<div class="" style="padding-top: 100px;overflow: hidden;min-height: 79vh;">
    <app-breadcrumb></app-breadcrumb>
    <router-outlet></router-outlet>
</div>
<footer style="padding: 15px;">
    <div class="container">
        <p></p>
        <p class="copyright-text">Copyright &copy; {{currentYear}} | All Rights Reserved</p>
        <ul class="social-footer">
            <li>
                <a href="https://www.facebook.com/canvialife/" target="_blank" title="Facebook"><img style="width: 50px"
                        src="./assets/newImgs/fb.svg"></a>
            </li>
            <li>
                <a href="https://twitter.com/CanviaArt" target="_blank" title="Twitter"><img style="width: 50px"
                        src="./assets/newImgs/twitter.svg"></a>
            </li>
            <li>
                <a href="https://www.instagram.com/canvia.art/ " target="_blank" title="Instagram"><img
                        style="width: 50px" src="./assets/newImgs/insta.svg"></a>
            </li>
        </ul>
        <p></p>
    </div>
</footer>
<app-playlist-bar style="position: fixed;
    bottom: 0px;
    z-index: 100;
    width: 100%;" *ngIf="hasDevices$ | async"></app-playlist-bar>