import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import { Store } from '@ngrx/store';
import { APP_INIT } from '@store/app.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  constructor(
    private router: Router,private gtmService: GoogleTagManagerService,
    private store: Store, 
    ) {
    this.router.events.subscribe((event) => {
    });
  }

  ngOnInit() {
    this.store.dispatch(APP_INIT());
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
          const gtmTag = {
              event: 'page',
              pageName: item.url
          };
          console.log(gtmTag);
          this.gtmService.pushTag(gtmTag);
      }
  });
  }
}
