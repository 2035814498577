import { createSelector, createFeatureSelector } from "@ngrx/store";

import { AppState } from "../app.state";
import { UI } from "@models/ui.model";

import * as RouteSelects from '../router.selectors';

export const selectUI = createFeatureSelector<
AppState,
UI
>("ui");

export const selectIdToLabelMap = createSelector(
    selectUI,
    (ui: UI) => ui.id_to_label_map
);

export const selectIdToLabelEntities = createSelector(
    selectUI,
    (ui: UI) => ui.id_to_label_map.entities
);

export const selectBreadcrumbs = createSelector(
    RouteSelects.selectRouteData,
    RouteSelects.selectRouteParams,
    selectIdToLabelEntities,
    (data, params, idToLabel) => {
        if (!('breadcrumb' in data)){
            return [];
        }
        let breadcrumb = data['breadcrumb'];
        if (!breadcrumb){
            return [];
        }
        // We will now examine the breadcrumbs
        breadcrumb = breadcrumb.map (v => {
            let label = v.label;
            let url = v.url;
            for (let param in params){
                let value  = params[param];
                url = url.replace(param, value);
                if (value in idToLabel){
                    value = idToLabel[value].label;
                }
                label = label.replace(param, value);
            }
            return {
                label: label,
                url: url,
            }
        });
        return breadcrumb;
    }
);

