import { NowPlaying } from '@models/device.model';
import { createAction, props } from '@ngrx/store';


export const changeCurrentDevice = createAction(
    '[System] changeCurrentDevice',
    props<{ id: string}>()
);

export const changeCurrentDeviceError = createAction(
    '[System] changeCurrentDeviceError',
    props<{ error: any}>()
);

export const setCurrentDevice = createAction(
    '[Device List] setCurrentDevice',
    props<{ id: string}>()
);

export const setOnlineStatus = createAction(
    '[Socket-IO] setOnlineStatus',
    props<{ online: boolean}>()
);

export const setNowPlaying = createAction(
    '[Socket-IO] setNowPlaying',
    props<{ payload: any}>()
);

export const setNowPlayingFromQueue = createAction(
    '[Socket-IO] setNowPlayingFromQueue',
    props<{ payload: NowPlaying}>()
);


export const sioAckReceived = createAction(
    '[Socket-IO] sioAckReceived',
    props<{ ack: any}>()
);

export const sioEventReceived = createAction(
    '[Socket-IO] sioEventReceived',
    props<{ event: string, module: string, details: any}>()
);

export const queueContentsReceived = createAction(
    '[Socket-IO] queueContentsReceived',
    props<{ payload: any}>()
);

export const sioPlaybackCommandSent = createAction(
    '[Socket-IO] sioPlaybackCommandSent',
    props<{ command: any}>()
);

export const showQueue = createAction(
    '[Player] showQueue'
);

export const hideQueue = createAction(
    '[Player] hideQueue'
);

export const toggleQueue = createAction(
    '[Player] toggleQueue'
);
