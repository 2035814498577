import { AfterViewInit, Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { SocketService } from '../../services/socket.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BrowseService } from '../../services/browse.service';
import { ShowArtworkModalComponent } from '../../collection/modals/ShowArtworkModal';
import { NgxSpinnerService } from "ngx-spinner";
import { Store } from '@ngrx/store';
import * as DeviceSelects from '@store/device/device.selectors';
import * as QueueSelects from '@store/device/queue.selectors';
import { getCurrentDeviceId, isDeviceOnline, isQueueShuffle } from '@store/util';
import * as DeviceActions from '@store/device/device.actions';
import * as Toaster from '@store/toaster';

// Menu Items

@Component({
  selector: 'app-playlist-bar',
  templateUrl: './playlist-bar.component.html',
  styleUrls: ['./playlist-bar.component.scss']
})
export class PlaylistBarComponent implements OnInit, AfterViewInit {
  // Deivce list
  devices$ = this.store.select(DeviceSelects.selectAllDevices);
  no_devices_flag$ = this.store.select(DeviceSelects.selectNoDevices);
  // Current device
  online$ = this.store.select(DeviceSelects.selectIsDeviceOnline);
  // Now playling stuff
  now_playing$ = this.store.select(DeviceSelects.selectNowPlaying);
  now_playing_title$ = this.store.select(DeviceSelects.selectNowPlayingTitle);
  now_playing_tn$ = this.store.select(DeviceSelects.selectNowPlayingThumbnail);
  // UI related stuff
  queue_visible$ = this.store.select(DeviceSelects.selectIsQueueVisible);
  /// Queue related stuff
  is_playing$ = this.store.select(QueueSelects.selectIsPlaying);
  is_shuffle$ = this.store.select(QueueSelects.selectIsShuffle);
  queue$ = this.store.select(QueueSelects.selectQueue);
  queue_total$ = this.store.select(QueueSelects.selectTotal);
  queue_is_empty$ = this.store.select(QueueSelects.selectIsEmpty);
  queue_is_not_empty$ = this.store.select(QueueSelects.selectIsNotEmpty);
  now_playing_index$ = this.store.select(QueueSelects.selectNowPlayingIndex);
  // local variables
  moreOptionModal = false;
  /// form binding for current device id
  device_id;
  modalRef: BsModalRef;
  constructor(
    private browseService: BrowseService,
    private modalService: BsModalService,
    private userService: UserService,
    private socketService: SocketService,
    public router: Router,
    private spinner: NgxSpinnerService,
    private store: Store) {
  }

  openmoreOptionModal() {
    this.moreOptionModal = true
  }
  deleteArtworkFromQueue(count, art_id, e) {
    e.stopImmediatePropagation();
    this.socketService.deleteArtworkFromQueue(count, art_id);
  }
  deleteArtworkFromURLQueue(count, url, e) {
    e.stopImmediatePropagation();
    this.socketService.deleteArtworkFromURLQueue(count, url);
  }

  deletePlaylistFromQueue(index_1, id, e) {
    e.stopImmediatePropagation();
    this.socketService.deletePlaylistFromQueue(index_1, id);
  }

  playArtworkFromQueue(count, art_id, url) {
    if (url) {
      this.playArtworkFromURL(count, url)
    } else {
      this.socketService.playArtworkFromQueue(count, art_id);
      console.log(count, art_id);
    }
  }
  playArtworkFromURL(count, url) {
    this.socketService.playArtworkFromURLQueue(count, url)
  }

  changeDevice() {
    this.store.dispatch(DeviceActions.changeCurrentDevice({ id: this.device_id }));
  }

  openmoreOption() {
    $('#moreOption').modal('show')
  }
  showOffineMessage(): void {
    Toaster.Error(this.store, 'Device is offline.');
  }

  ngOnInit() {
    this.device_id = getCurrentDeviceId(this.store);
    this.socketService.activateSocket(this.device_id);
    this.now_playing_index$.subscribe(value => {
      this.scrollQueue(value - 3);
    });
  }

  scrollQueue(n) {
    if (n < 0){
      n = 0;
    }
    document.getElementById('queue-log').scrollTo(0, (65 * n));
  }

  play() {
    let online = isDeviceOnline(this.store);
    if (online) {
      this.socketService.socketCommands('play');
    }
    else {
      this.showOffineMessage();
    }
  }

  pause() {
    let online = isDeviceOnline(this.store);
    if (online) {
      this.socketService.socketCommands('pause');
    }
    else {
      this.showOffineMessage();
    }
  }

  next() {
    let online = isDeviceOnline(this.store);
    if (online) {
      this.socketService.socketCommands('next');
    }
    else {
      this.showOffineMessage();
    }
  }

  pre() {
    let online = isDeviceOnline(this.store);
    if (online) {
      this.socketService.socketCommands('previous');
    }
    else {
      this.showOffineMessage();
    }
  }

  showQueue() {
    let online = isDeviceOnline(this.store);
    if (online) {
      this.store.dispatch(DeviceActions.toggleQueue());
    }
    else {
      this.showOffineMessage();
    }
  }

  flushQueue() {
    this.userService.flushQueue().subscribe(value => {
      Toaster.Success(this.store, 'The Queue has been cleared');
      // $('.all-queue').fadeToggle();
      // this.isQueueOpen=!this.isQueueOpen;
      this.socketService.getQueue();
    }, error1 => {
      Toaster.Error(this.store, error1.error.msg || 'Some error occurred');
    });
  }

  switchShuffle() {
    let shuffle = ! isQueueShuffle(this.store);
    this.userService.setShuffle(this.device_id, shuffle).subscribe(value => {
    }, error1 => {
      Toaster.Error(this.store, error1.error.msg || 'Some error occurred');
    });
  }

  ngAfterViewInit() {
    $(document).ready(function () {
      $('.close-player-mobile').click(function () {
        $('.bottom-fixed').fadeToggle();
        
      });
    });
    this.queue_visible$.subscribe(v => {
      if (v){
        $('.all-queue').fadeIn();
      } else {
        $('.all-queue').fadeOut();
      }
    });
    this.store.dispatch(DeviceActions.hideQueue());
  }

  showArtworks(id, e) {
    e.stopImmediatePropagation();
    this.spinner.show()
    this.browseService.getArtworkById(id).subscribe(value => {
      this.spinner.hide()
      if (value['orientation'] === 'v') {
        this.modalRef = this.modalService.show(ShowArtworkModalComponent,
          Object.assign({}, { class: 'gray modal-lg2' }));
      } else {
        this.modalRef = this.modalService.show(ShowArtworkModalComponent,
          Object.assign({}, { class: 'gray modal-lg' }));
      }
      this.modalRef.content.title = value['title'];
      this.modalRef.content.id = value['_id'];
      this.modalRef.content.thumbnail = value['thumbnail'];
      this.modalRef.content.artist = value['artist'];
      this.modalRef.content.date = value['date'];
      this.modalRef.content.credit = value['credit'];
      this.modalRef.content.medium = value['medium'];
      this.modalRef.content.tags = value['tags'];
      this.modalRef.content.accession = value['accession'];
      this.modalRef.content.dimensions = value['dimensions'];
      this.modalRef.content.painting_styles = value['painting_styles'];
      this.modalRef.content.art_forms = value['art_forms'];
      this.modalRef.content.art_types = value['art_types'];
      this.modalRef.content.classification = value['classification'];
      this.modalRef.content.details = value['details'];
      this.modalRef.content.source = value['source'];
      this.modalRef.content.orientation = value['orientation'];
      this.modalRef.content.thumbnail = value['full_medium_image'];
    });
    this.spinner.show()
    this.browseService.getPlaylistsOfArtwork(id).subscribe(value => {
      this.modalRef.content.playlists = value;
      this.spinner.hide()
    });
  }
}
