export const environment = {
  production: false,
  api_Url: "https://dev.palacio.life/backend/api/v1/",
  base_url: "https://dev-my.canvia.art",
  device_url: "https://dev.palacio.life",
  stripePublicKey: "pk_test_4KGY7fMsabwOyB5ToNEd354g",
  paymentToken:
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjEyOTksImlzcyI6Imh0dHA6Ly9wYWxhY2lvLnBsYXRmb3JtLnNpbXBsaWZpaS54eXov" +
    "YXBpL3YxL2FkbWluL2F1dGhlbnRpY2F0ZSIsImlhdCI6MTU0MDczNjA0OCwiZXhwIjoxNjAxMjE2MDQ4LCJuYmYiOjE1NDA3MzYwNDgsImp0aSI6Imh3clA5OThGO" +
    "FNwb0dKREEifQ.GsAHy3FFcoBzor-iKaxfotxHwF35ephfo-S64r3-6cc",
  SENTRY_DSN:
    "https://ae0be57f55d4e808dad4e1077eb51ee3@o4506392577179648.ingest.sentry.io/4506392581439488",
  SENTRY_AUTH_TOKEN:
    "sntrys_eyJpYXQiOjE3MDI2MTQ0MzQuNjI2OTM5LCJ1cmwiOiJodHRwczovL3NlbnRyeS5pbyIsInJlZ2lvbl91cmwiOiJodHRwczovL3VzLnN" +
    "lbnRyeS5pbyIsIm9yZyI6InBhbGFjaW8ifQ==_GfxCxhn1PmJu8B2NKnzB/8Jf1+ZorDJ9+8cOtPUPU5c",
  SENTRY_ORG: "palacio",
  SENTRY_PROJECT: "my-canvia",
};
