import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })

export class PlaylistService {

  constructor(
    private http: HttpClient) {
  }

  createPlaylist(request) {
    console.log(request)
    const tok = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': tok,
        'x-user-id': localStorage.getItem('username')
      })
    };
    return this.http.post<any>(environment.api_Url + 'playlists', request, httpOptions)
      .pipe(
        map(data => data)
      );
  }

  RemoveFavArtist(id) {
    const tok = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': tok,
        'x-user-id': localStorage.getItem('username')
      })
    };
    return this.http.post<any>(environment.api_Url + 'preferences/remove_artist', { artist: id }, httpOptions)
      .pipe(
        map(data => data)
      );
  }

  RemoveFavPlaylist(id) {
    const tok = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': tok,
        'x-user-id': localStorage.getItem('username')
      })
    };
    return this.http.post<any>(environment.api_Url + 'preferences/remove_playlist', { playlist: id }, httpOptions)
      .pipe(
        map(data => data)
      );
  }

  updatingPlaylist(request, id) {
    const tok = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': tok,
        'x-user-id': localStorage.getItem('username')
      })
    };
    return this.http.put<any>(environment.api_Url + 'playlists/' + id, request, httpOptions)
      .pipe(
        map(data => data)
      );
  }

  deletePlaylist(id) {
    const tok = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': tok,
        'x-user-id': localStorage.getItem('username')
      })
    };
    return this.http.delete<any>(environment.api_Url + 'playlists/' + id, httpOptions)
      .pipe(
        map(data => data)
      );
  }

  getArtsOfArtist(id) {
    const tok = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': tok,
        'x-user-id': localStorage.getItem('username')
      })
    };
    return this.http.get<any>(environment.api_Url + 'artists/' + id + '/artworks?page=1&limit=50', httpOptions)
      .pipe(
        map(data => data)
      );
  }

  addArtworkToPlaylist(request) {
    const tok = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': tok,
        'x-user-id': localStorage.getItem('username')
      })
    };
    return this.http.post<any>(environment.api_Url + 'playlists/add_artwork', request, httpOptions)
      .pipe(
        map(data => data)
      );
  }

  getMyPlaylists() {
    const tok = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': tok,
        'x-user-id': localStorage.getItem('username')
      })
    };
    return this.http.get<any>(environment.api_Url + 'playlists/my?page=' + 1 + '&p=orientation&limit=50&p=details&p=tags&p=public&p=shared&p=published&p=name&p=userId&p=aw_count&p=cover', httpOptions)
      .pipe(
        map(data => data)
      );
  }

  getFavouriteArtists() {
    const tok = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': tok,
        'x-user-id': localStorage.getItem('username')
      })
    };
    return this.http.get<any>(environment.api_Url + 'preferences/artists?page=' + 1 + '&limit=50&p=cover_thumbnail_url', httpOptions)
      .pipe(
        map(data => data)
      );
  }

  getfavouritePlaylists() {
    const tok = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-access-token': tok,
        'x-user-id': localStorage.getItem('username')
      })
    };
    return this.http.get<any>(environment.api_Url + 'preferences/playlists?page=' + 1 + '&limit=50&p=details&p=tags&p=public&p=shared&p=orientation&p=published&p=name&p=userId&p=aw_count&p=cover', httpOptions)
      .pipe(
        map(data => data)
      );
  }
}
