import { createSelector, createFeatureSelector } from "@ngrx/store";
import { CurrentUser } from "@models/currentuser.model";

import { AppState } from "../app.state";

export const currentUser = createFeatureSelector<
    AppState,
    CurrentUser
>("user");

export const selectIsLoggedIn = createSelector(
    currentUser,
    (user: CurrentUser) => {
        return user.isLoggedIn
    }
);

export const selectToken = createSelector(
    currentUser,
    (user: CurrentUser) => {
        return user.token
    }
);

export const selectProfile = createSelector(
    currentUser,
    (user: CurrentUser) => {
        return user.info
    }
);

export const selectEmail = createSelector(
    currentUser,
    (user: CurrentUser) => {
        return user.info ? user.info.email : ''
    }
);

export const selectFullName = createSelector(
    currentUser,
    (user: CurrentUser) => {
        return user.info ? `${user.info.first_name} ${user.info.last_name}` : ''
    }
);

export const selectFirstName = createSelector(
    currentUser,
    (user: CurrentUser) => {
        return user.info ? user.info.first_name : ''
    }
);

export const selectLastName = createSelector(
    currentUser,
    (user: CurrentUser) => {
        return user.info ? user.info.last_name : ''
    }
);

export const selectGoogleProfile = createSelector(
    currentUser,
    (user: CurrentUser) => {
        return user.info ? user.info.google : null;
    }
);

export const selectIsGoogleLinked = createSelector(
    currentUser,
    (user: CurrentUser) => {
        return user.info && user.info.google && user.info.google.id && true;
    }
);

export const selectName = createSelector(
    currentUser,
    (user: CurrentUser) => {
        let name = '';
        if (user.info){
            let info = user.info;
            if (info.first_name){
                name = info.first_name;
            }
            else if(info.last_name){
                name = info.last_name;
            }
        }
        return name;
    }
);


export const selectNumDevices = createSelector(
    currentUser,
    (user: CurrentUser) => {
        return user.info ? user.info.num_devices : 0
    }
);

export const selectHasDevices = createSelector(
    currentUser,
    (user: CurrentUser) => {
        if (!user.isLoggedIn){
            return false;
        }
        if (!user.info){
            return false;
        }
        return user.info.num_devices > 0;
    }
);

export const selectTier = createSelector(
    currentUser,
    (user: CurrentUser) => {
        return user.info ? user.info.tier : ''
    }
);

