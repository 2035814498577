import { createSelector, createFeatureSelector } from "@ngrx/store";
import { QueueEntry, QueueModel } from "@models/queue.model";
import { AppState } from "../app.state";

export const selectQueueModel = createFeatureSelector<
    AppState,
    QueueModel
>("queue");


export const selectQueue = createSelector(
    selectQueueModel,
    (model: QueueModel) => {
        return model.queue
    }
);

export const selectIsPlaying = createSelector(
    selectQueueModel,
    (model: QueueModel) => {
        return model.is_playing
    }
);

export const selectIsShuffle = createSelector(
    selectQueueModel,
    (model: QueueModel) => {
        return model.shuffle
    }
);

export const selectTotal = createSelector(
    selectQueueModel,
    (model: QueueModel) => {
        return model.total
    }
);

export const selectIsEmpty = createSelector(
    selectQueueModel,
    (model: QueueModel) => {
        return model.total == 0
    }
);

export const selectIsNotEmpty = createSelector(
    selectQueueModel,
    (model: QueueModel) => {
        return model.total > 0
    }
);

export const selectPlaybackDuration = createSelector(
    selectQueueModel,
    (model: QueueModel) => {
        return model.playback_duration
    }
);

export const selectNowPlayingIndex = createSelector(
    selectQueue,
    (queue: QueueEntry[]) => {
        if (!queue || queue.length == 0){
            return 0;
        }
        let index =  queue.findIndex(item => item.now_playing === true);
        if (index < 0) {
            index = 0;
        }
        return index;
    }
);
